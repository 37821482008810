import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Breadcrumbs,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Container, IconButton, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  useIncubationDetailsQuery,
  useCreateProgressReportMutation,
  useGetDistrictQuery,
  useUploadDetailFileMutation,
  api,
  useUpdateProgressReportMutation,
} from "../../services/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import validationSchema from "./schema";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LoadingButton from "@mui/lab/LoadingButton";
import PublishIcon from "@mui/icons-material/Publish";

const UpsertProgressReport = () => {
  const { id } = useParams();
  const [prRoute] = api.endpoints.singleProgressReport.useLazyQuery();
  const [singleApplication] = api.endpoints.singleApplicant.useLazyQuery();
  const location = useLocation();
  const checkVal = location.pathname.includes("add");
  const Option = location.pathname.includes("edit");
  const redirectId = Option ? location.pathname.slice(17, 41) : id;
  const {
    data: incubationList,
    isLoading,
    isError,
  } = useIncubationDetailsQuery();
  const [createPR] = useCreateProgressReportMutation();
  // const [updPR] = useUpdateProgressReportMutation();
  const [uploadFile] = useUploadDetailFileMutation();
  const navigate = useNavigate();
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef([]);
  const fileInputRef4 = useRef(null);
  const { data: districtList } = useGetDistrictQuery(); // Get Incubation list
  const [loading, setLoading] = useState(false);
  if (!Array.isArray(fileInputRef3.current)) {
    fileInputRef3.current = [];
  }
  const formik = useFormik({
    initialValues: {
      report_number: "",
      startup_name: "",
      founder_name: "",
      women_led_startup: "no",
      category: "",
      incubation_center: "",
      incubationStartDate: new Date(),
      mentor_name: "",
      address: "",
      district: "",
      mobile_number: "",
      email: "",
      // state_startup_registration_number: "",
      // incorporation_number: "",
      task_completed: "",
      prev_report_num: 0,
      summary: "",
      current_stage: "",
      turn_over: 0,
      turn_over_proof: "",
      investment_funding: "",
      events_attended: "",
      turn_over_name: "",
      upload_doc_name: "",
      investment_funding_name: "",
      fieldsCount: 1,
      custom_fields: [
        { event: "", date: new Date(), details: null, details_name: null },
      ],
      industryCount: 1,
      industry_custom_fields: [
        { name: "", email: "", office_address: "", contact: "" },
      ],
      upload_doc: "",
      comment_by_mentor: "",
      comment_by_nodal_officer: "",
      comment_by_gm: "",
      comment_by_directorate_of_industries: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (id && location.pathname.includes("edit")) {
          setLoading(true);
          const payload = {
            process_reoprt_id: id,
            applicant_id: redirectId,
            name_founder: values.founder_name,
            women_led_startup: values.women_led_startup,
            category: values.category,
            incubation_centre: values.incubation_center,
            name_of_mantor: values.mentor_name,
            address: values.address,
            district: values.district,
            contact_number: values.mobile_number,
            email: values.email,
            // state_startup_registration_number:
            //   values.state_startup_registration_number,
            // incorporation_number: values.incorporation_number,
            task_complete: values.task_completed,
            previous_report_number: values.prev_report_num,
            summary: values.summary,
            current_stage: values.current_stage,
            turn_over: values.turn_over,
            turn_over_proof: values.turn_over_proof,
            investment_proof: values.investment_funding,
            upload_document: values.upload_doc,
            event_attended: JSON.stringify(values.custom_fields),
            industry_tie_up: JSON.stringify(values.industry_custom_fields),
            startup_name: values.startup_name,
            incubation_start_date: values.incubationStartDate,
          };
          const formData = new FormData();
          // Append each key-value pair to the FormData object
          Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key]);
          });
          const {
            data: updatedData,
            error: isCreateError,
            isLoading: isUserLoading,
          } = await createPR(formData);
          if (updatedData?.success) {
            let time = 2000;
            toast.success("Progress Report Updated successfully", {
              position: "top-center",
              autoClose: time,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            setTimeout(() => {
              navigate(`/progress-report/${redirectId}`);
            }, time + 1000);
          }
        } else {
          setLoading(true);
          const payload = {
            //updated payload
            applicant_id: id,
            name_founder: values.founder_name,
            women_led_startup: values.women_led_startup,
            category: values.category,
            incubation_centre: values.incubation_center,
            name_of_mantor: values.mentor_name,
            address: values.address,
            district: values.district,
            contact_number: values.mobile_number,
            email: values.email,
            // state_startup_registration_number:
            //   values.state_startup_registration_number,
            // incorporation_number: values.incorporation_number,
            task_complete: values.task_completed,
            previous_report_number: values.prev_report_num,
            summary: values.summary,
            current_stage: values.current_stage,
            turn_over: values.turn_over,
            turn_over_proof: values.turn_over_proof,
            investment_proof: values.investment_funding,
            upload_document: values.upload_doc,
            event_attended: JSON.stringify(values.custom_fields),
            industry_tie_up: JSON.stringify(values.industry_custom_fields),
            startup_name: values.startup_name,
            incubation_start_date: values.incubationStartDate,
          };

          const formData = new FormData();

          // Append each key-value pair to the FormData object
          Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key]);
          });
          const {
            data: userData,
            error: isCreateError,
            isLoading: isUserLoading,
          } = await createPR(formData);
          if (userData && userData?.success === true) {
            const time = 2000;
            toast.success("Progress Report created successfully", {
              position: "top-center",
              autoClose: time,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            setTimeout(() => {
              navigate(`/progress-report/${redirectId}`);
            }, time + 1000);
          }

          if (isCreateError?.data && !isUserLoading) {
            setLoading(false);
            toast.error(isCreateError.data.message ?? "Something went wrong", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error as needed, for example:
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    },
  });

  const handleFileUpload = (event, fieldName) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue(fieldName, file);
    if (fieldName === "turn_over_proof")
      formik.setFieldValue("turn_over_name", file.name);
    if (fieldName === "investment_funding")
      formik.setFieldValue("investment_funding_name", file.name);
    if (fieldName === "upload_doc")
      formik.setFieldValue("upload_doc_name", file.name);
  };
  console.log("first", formik.errors, formik?.values?.industry_custom_fields.length)
  React.useEffect(() => {
    const addPrWithPrefilledData = async () => {
      if (id) {
        try {
          const { data } = await singleApplication(id);
          if (data?.application) {
            const {
              founder_name,
              women_led,
              category,
              incubation_centre,
              founder_address,
              email,
              contact,
              founder_district,
              startup_name,
              incorporation_number,
              mantor_id
            } = data?.application;
            await formik.setValues((prev) => ({
              ...prev,
              startup_name,
              founder_name,
              women_led_startup: women_led,
              category,
              incubation_center: incubation_centre,
              address: founder_address,
              district: founder_district,
              mobile_number: contact,
              email: email,
              incorporation_number: incorporation_number,
              mentor_name:mantor_id?.name
            }));
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    addPrWithPrefilledData();
  }, [id, !Option]);
  React.useEffect(() => {
    const UpdatePR = async () => {
      if (id) {
        try {
          const { data } = await prRoute(id);
          
          if (data && data?.data) {
            const {
              name_founder,
              women_led_startup,
              category,
              incubation_centre,
              address,
              email,
              contact_number,
              district,
              startup_name,
              incorporation_number,
              name_of_mantor,
              task_complete,
              previous_report_number,
              summary,
              current_stage,
              turn_over,
              turn_over_proof,
              investment_proof,
              upload_document,
              event_attended,
              industry_tie_up,
              incubation_start_date,
              report_number,
              state_startup_registration_number,
              mantor_id
            } = data?.data;

            await formik.setValues((prev) => ({
              ...prev,
              report_number,
              startup_name,
              founder_name: name_founder,
              mentor_name: name_of_mantor,
              women_led_startup,
              category,
              incubation_center: incubation_centre,
              address,
              district,
              mobile_number: contact_number,
              email,
              // incorporation_number: incorporation_number,
              // state_startup_registration_number:
              //   state_startup_registration_number,
              task_completed: task_complete,
              prev_report_num: previous_report_number,
              summary: summary,
              current_stage: current_stage,
              turn_over: turn_over,
              turn_over_proof: turn_over_proof,
              investment_funding: investment_proof,
              // industry_tie_ups: "",
              // documents: "",
              // turn_over_name: "",
              // upload_doc_name: "",
              // investment_funding_name: "",
              fieldsCount: JSON.parse(event_attended).length,
              custom_fields: JSON.parse(event_attended),
              industryCount: JSON.parse(industry_tie_up).length,
              industry_custom_fields: JSON.parse(industry_tie_up),
              upload_doc: upload_document,
            }));
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdatePR();
  }, [id, Option]);
  const handleChange = async (index, fieldName, value) => {
    const updatedCustomFields = [...formik.values.custom_fields];
    if (fieldName === "details") {
      updatedCustomFields[index]["details_name"] = value?.name;
      const formData = new FormData();
      formData.append("file_pdf", value);
      const res = await uploadFile(formData);
      updatedCustomFields[index]["details"] = res?.data?.data?.file_pdf;
    } else {
      updatedCustomFields[index][fieldName] = value;
    }
    formik.setFieldValue("custom_fields", updatedCustomFields);
  };

  const handleDecreaseFields = () => {
    if (formik.values.fieldsCount > 1) {
      formik.setFieldValue("fieldsCount", formik.values.fieldsCount - 1);
      formik.setFieldValue(
        "custom_fields",
        formik.values.custom_fields.slice(0, -1)
      ); // Remove the last set of fields
    }
  };
  const handleIncreaseFields = () => {
    formik.setFieldValue("fieldsCount", formik.values.fieldsCount + 1);
    formik.setFieldValue("custom_fields", [
      ...formik.values.custom_fields,
      { event: "", date: new Date(), details: null, details_name: null }, // Add an empty set of fields
    ]);
  };

  const handleIndustryChange = (index, field, value) => {
    const newIndustryFields = [...formik.values.industry_custom_fields];
    newIndustryFields[index][field] = value; // Update the specific field of the industry at the given index
    formik.setFieldValue("industry_custom_fields", newIndustryFields);
  };

  const handleDecreaseIndustryFields = () => {
    if (formik.values.fieldsCount > 1) {
      formik.setFieldValue("industryCount", formik.values.industryCount - 1);
      formik.setFieldValue(
        "industry_custom_fields",
        formik.values.industry_custom_fields.slice(0, -1)
      ); // Remove the last set of fields
    }
  };
  const handleIncreaseIndustryFields = () => {
    formik.setFieldValue("industryCount", formik.values.industryCount + 1);
    formik.setFieldValue("industry_custom_fields", [
      ...formik.values.industry_custom_fields,
      { name: "", email: "", office_address: "", contact: "" }, // Add an empty set of fields
    ]);
  };
  return (
    <Box>
      <ToastContainer />
      <Box marginTop={"6em"} padding={4}>
        <Paper className="paper-card">
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h5">Progress Report</Typography>
            </Grid>
          </Grid>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>
              {/* incubation Type */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  inputProps={{
                    readOnly: true,
                  }}
                  disabled={id ? true : false}
                  // required
                  fullWidth
                  id="report_number"
                  label="Report Number"
                  name="report_number"
                  placeholder="Report Number"
                  autoComplete="report_number"
                  value={formik.values.report_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.report_number &&
                    Boolean(formik.errors.report_number)
                  }
                  helperText={
                    formik.touched.report_number && formik.errors.report_number
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  aria-readonly={true}
                  id="startup_name"
                  label="Startup Name"
                  name="startup_name"
                  placeholder="Startup Name"
                  autoComplete="startup_name"
                  value={formik.values.startup_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.startup_name &&
                    Boolean(formik.errors.startup_name)
                  }
                  helperText={
                    formik.touched.startup_name && formik.errors.startup_name
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="founder_name"
                  label="Founder Name"
                  name="founder_name"
                  aria-readonly
                  placeholder="Founder Name"
                  autoComplete="founder_name"
                  value={formik.values.founder_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.founder_name &&
                    Boolean(formik.errors.founder_name)
                  }
                  helperText={
                    formik.touched.founder_name && formik.errors.founder_name
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>

              {/* Date of birth */}

              {/* Gender */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="women-led-startup-label">
                    Women Led Start Up
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="women-led-startup-label"
                    name="women_led_startup" // Ensure this matches formik initialValues key
                    value={formik.values.women_led_startup}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="Category">Select Category</InputLabel>
                  <Select
                    labelId="Select Category"
                    required
                    fullWidth
                    id="Category"
                    label="Category"
                    name="category" // Ensure the name matches the formik initialValues key
                    autoComplete="off"
                    placeholder="Category"
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  >
                    {["ST", "SC", "OBC", "General"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="incubation_center">
                    Incubation Centre
                  </InputLabel>
                  <Select
                    labelId="Select Incubation Centre"
                    required
                    fullWidth
                    id="incubation_center"
                    label="Incubation Center"
                    name="incubation_center"
                    autoComplete="off"
                    placeholder="incubation_center"
                    defaultValue={""}
                    onChange={formik.handleChange}
                    value={formik.values.incubation_center}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.incubation_center &&
                      Boolean(formik.errors.incubation_center)
                    }
                    helperText={
                      formik.touched.incubation_center &&
                      formik.errors.incubation_center
                    }
                    //   onChange={(e) => HandleFilterPostChange(e)}
                  >
                    {incubationList?.data?.map((item, index) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.host_institute_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <DatePicker
                  disableFuture
                  sx={{
                    width: "100%",
                    marginTop: "1em",
                    marginBottom: "0.5em",
                  }}
                  value={dayjs(
                    moment(formik.values.incubationStartDate).format(
                      "YYYY-MM-DD"
                    )
                  )}
                  selected={formik.values.incubationStartDate}
                  name="incubationStartDate"
                  label="Date of birth"
                  margin="normal"
                  onChange={(value) =>
                    formik.setFieldValue("incubationStartDate", value["$d"])
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                inputProps={{readOnly:true}}
                  margin="normal"
                  required
                  fullWidth
                  id="Mentor Name"
                  label="Mentor Name"
                  name="mentor_name"
                  aria-readonly
                  placeholder="Mentor Name"
                  autoComplete="mentor_name"
                  value={formik.values.mentor_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mentor_name &&
                    Boolean(formik.errors.mentor_name)
                  }
                  helperText={
                    formik.touched.mentor_name && formik.errors.mentor_name
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  aria-readonly
                  placeholder="Address"
                  autoComplete="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="district">Select District</InputLabel>
                  <Select
                    labelId="Select District"
                    required
                    fullWidth
                    id="district"
                    label="District"
                    name="district" // Ensure the name matches the formik initialValues key
                    autoComplete="off"
                    placeholder="district"
                    onChange={formik.handleChange}
                    value={formik.values.district}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched.district && formik.errors.district
                    }
                  >
                    {districtList?.data?.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="number"
                  label="Mobile Number"
                  name="mobile_number"
                  autoComplete="mobile_number"
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mobile_number &&
                    Boolean(formik.errors.mobile_number)
                  }
                  helperText={
                    formik.touched.mobile_number && formik.errors.mobile_number
                  }
                />
              </Grid>

              {/* Email */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  autoComplete="email"
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="registration_number"
                  label="State StartUp Registration Number"
                  name="state_startup_registration_number"
                  value={formik.values.state_startup_registration_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.state_startup_registration_number &&
                    Boolean(formik.errors.state_startup_registration_number)
                  }
                  helperText={
                    formik.touched.state_startup_registration_number &&
                    formik.errors.state_startup_registration_number
                  }
                  autoComplete="state_startup_registration_number"
                />
              </Grid> */}
              {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="incorporation_number"
                  label="Incorporation Number"
                  name="incorporation_number"
                  value={formik.values.incorporation_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incorporation_number &&
                    Boolean(formik.errors.incorporation_number)
                  }
                  helperText={
                    formik.touched.incorporation_number &&
                    formik.errors.incorporation_number
                  }
                  autoComplete="incorporation_number"
                  // onChange={(e) => handleChange(e)}
                />
              </Grid> */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="Task_Completed">
                    Select Task Completed
                  </InputLabel>
                  <Select
                    labelId="Select Task Completed"
                    required
                    fullWidth
                    id="Task_Completed"
                    label="Task Completed"
                    name="task_completed"
                    autoComplete="off"
                    placeholder="Task Completed"
                    defaultValue={""}
                    value={formik.values.task_completed}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.task_completed &&
                      Boolean(formik.errors.task_completed)
                    }
                    helperText={
                      formik.touched.task_completed &&
                      formik.errors.task_completed
                    }
                  >
                    {["Task1", "Task2", "Task3"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.task_completed && formik.errors.task_completed && (
                                        <Typography sx={{ color: "#d32f2f", fontSize: "12px" }}>
                                          {formik.errors.task_completed}
                                        </Typography>
                                      )}
                </FormControl>
              </Grid>
              {/* 
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={4}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      sx={{ display: "flex", alignItems: "center" }}
                      color="inherit"
                      href="/"
                    >
                      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      MUI
                    </Link>
                    <Link
                      underline="hover"
                      sx={{ display: "flex", alignItems: "center" }}
                      color="inherit"
                      href="/material-ui/getting-started/installation/"
                    >
                      <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Core
                    </Link>
                    <Typography
                      sx={{ display: "flex", alignItems: "center" }}
                      color="text.primary"
                    >
                      <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Breadcrumb
                    </Typography>
                  </Breadcrumbs>
                </Grid> */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    margin="normal"
                    type="textarea"
                    rows={2}
                    multiline
                    required
                    fullWidth
                    id="summary"
                    label="Summary"
                    name="summary"
                    value={formik.values.summary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.summary && Boolean(formik.errors.summary)
                    }
                    helperText={formik.touched.summary && formik.errors.summary}
                    autoComplete="summary"
                  />
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="current_stage">Current Stage</InputLabel>
                  <Select
                    labelId="Select Incubation Centre"
                    required
                    fullWidth
                    id="current_stage"
                    label="Incubation Center"
                    name="current_stage"
                    autoComplete="off"
                    placeholder="current_stage"
                    defaultValue={""}
                    value={formik.values.current_stage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.current_stage &&
                      Boolean(formik.errors.current_stage)
                    }
                    helperText={
                      formik.touched.current_stage &&
                      formik.errors.current_stage
                    }
                  >
                    {[
                      "Ideation",
                      "Prototype development",
                      "Under Commercialisation",
                      "Commercialisation",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                        {formik.touched.current_stage && formik.errors.current_stage && (
                                        <Typography sx={{ color: "#d32f2f", fontSize: "12px" }}>
                                          {formik.errors.current_stage}
                                        </Typography>
                                      )}
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  margin="normal"
                  type="number"
                  inputProps={{ min: 0 }}
                  rows={2}
                  required
                  fullWidth
                  id="turn_over"
                  label="Turn Over"
                  name="turn_over"
                  value={formik.values.turn_over}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.turn_over && Boolean(formik.errors.turn_over)
                  }
                  helperText={
                    formik.touched.turn_over && formik.errors.turn_over
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl
                  className="file-upload-style"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <CloudUploadIcon
                    className="icon-center"
                    style={{ cursor: "pointer", color: "#00bfff" }}
                    onClick={() => fileInputRef1.current.click()}
                  />
                  {formik?.values?.turn_over_proof ? (
                    <Link
                      to={
                        checkVal
                          ? URL.createObjectURL(formik?.values?.turn_over_proof)
                          : formik?.values?.turn_over_proof
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon color="primary" />
                    </Link>
                  ) : (
                    <></>
                  )}
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    fontSize={14}
                  >
                    {formik.values.turn_over_name}
                  </Typography>
                  <FormLabel>Turn Over Proof</FormLabel>
                  <input
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileUpload(event, "turn_over_proof")
                    }
                  />
                  {formik.errors.turn_over_proof &&
                    formik.touched.turn_over_proof && (
                      <Typography color="error">
                        {formik.errors.turn_over_proof}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl
                  className="file-upload-style"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <CloudUploadIcon
                    className="icon-center"
                    style={{ cursor: "pointer", color: "#00bfff" }}
                    onClick={() => fileInputRef2.current.click()}
                  />
                  {formik?.values?.investment_funding ? (
                    <Link
                      to={
                        !checkVal
                          ? formik?.values?.investment_funding
                          : URL.createObjectURL(
                              formik?.values?.investment_funding
                            )
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon color="primary" />
                    </Link>
                  ) : (
                    <></>
                  )}
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    fontSize={14}
                  >
                    {formik.values.investment_funding_name}
                  </Typography>
                  <FormLabel>Investment/Funding</FormLabel>
                  <input
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileUpload(event, "investment_funding")
                    }
                  />
                  {formik.errors.investment_funding &&
                    formik.touched.investment_funding && (
                      <Typography color="error">
                        {formik.errors.investment_funding}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              {/* <FormControl fullWidth> */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // mt={2}
                // display={"flex"}
                // sx={{ gap: 2 }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ fontWeight: 400, fontSize: "18px" }}
                >
                  Events Attended :
                </Typography>
              </Grid>

              {[...Array(formik.values.fieldsCount)].map((_, index) => (
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  // mt={2}
                  display={"flex"}
                  sx={{ gap: 2 }}
                >
                  <React.Fragment key={index}>
                    <Grid item xl={4} lg={4} md={4} sm={8} xs={8}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id={`field-${index}`}
                        label="Event"
                        name={`field-${index}`}
                        placeholder="Event"
                        autoComplete={`Event-${index}`}
                        value={formik.values?.custom_fields[index]?.event}
                        onChange={(e) =>
                          handleChange(index, "event", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.custom_fields && // Check if custom_fields array has been touched
                          formik.touched.custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.custom_fields && // Check if there are errors in custom_fields array
                          formik.errors.custom_fields[index]?.event // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.custom_fields && // Check if custom_fields array has been touched
                          formik.touched.custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.custom_fields && // Check if there are errors in custom_fields array
                          formik.errors.custom_fields[index]?.event // Show error message for this specific field
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={8} xs={8} mt={0.4}>
                      <DatePicker
                        disableFuture
                        sx={{
                          width: "100%",
                          marginTop: "1em",
                          marginBottom: "0.5em",
                        }}
                        value={dayjs(
                          moment(
                            formik.values?.custom_fields[index]?.date
                          ).format("YYYY-MM-DD")
                        )}
                        selected={formik.values?.custom_fields[index]?.date}
                        id={`date-${index}`}
                        label="Date"
                        name={`date-${index}`}
                        autoComplete={`date-${index}`}
                        margin="normal"
                        onChange={(e) => handleChange(index, "date", e["$d"])}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6} mt={2}>
                      <FormControl
                        className="file-upload-style"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                          color: (theme) => theme.palette.text.primary,
                        }}
                      >
                        <Grid display={"flex"} sx={{ gap: 1 }}>
                          <CloudUploadIcon
                            style={{ cursor: "pointer", color: "#00bfff" }}
                            onClick={() => fileInputRef3.current[index].click()}
                          />
                          {formik?.values?.custom_fields[index]?.details ? (
                            <Link
                              to={formik?.values?.custom_fields[index]?.details}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <VisibilityIcon color="primary" />
                            </Link>
                          ) : (
                            <></>
                          )}
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                            }}
                            fontSize={14}
                          >
                            {formik.values.custom_fields[index]?.details_name}
                          </Typography>
                          <input
                            type="file"
                            accept=".pdf"
                            style={{ display: "none" }}
                            ref={(el) => (fileInputRef3.current[index] = el)}
                            onChange={(event) =>
                              handleChange(
                                index,
                                "details",
                                event.target.files[0]
                              )
                            }
                          />
                          {formik.errors.custom_fields &&
                            formik.touched.custom_fields &&
                            formik.errors.custom_fields[index]?.details && (
                              <Typography color="error">
                                {formik.errors.custom_fields[index].details}
                              </Typography>
                            )}
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Details
                          </FormLabel>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
                      <IconButton
                        color="primary"
                        onClick={() => handleIncreaseFields(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                      {formik.values.custom_fields.length > 1 && (
                        <IconButton
                          color="secondary"
                          onClick={() => handleDecreaseFields(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                </Grid>
              ))}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // mt={2}
                // display={"flex"}
                // sx={{ gap: 2 }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ fontWeight: 400, fontSize: "18px" }}
                >
                  Industry Tie ups:
                </Typography>
              </Grid>
              {[...Array(formik.values.industryCount)].map((_, index) => (
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  display={"flex"}
                  sx={{ gap: 2 }}
                >
                  <React.Fragment key={index}>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id={`Name-${index}`}
                        label="Name"
                        name={`name-${index}`}
                        placeholder="Name"
                        autoComplete={`Name-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]?.name
                        }
                        onChange={(e) =>
                          handleIndustryChange(index, "name", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        // error={
                        //   formik.touched?.industry_custom_fields && // Check if industry_custom_fields array has been touched
                        //   formik.touched?.industry_custom_fields[index] && // Check if this specific field has been touched
                        //   formik.errors?.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                        //   formik.errors?.industry_custom_fields[index]?.name // Check if there's an error for this specific field
                        // }
                        // helperText={
                        //   formik.touched?.industry_custom_fields && // Check if industry_custom_fields array has been touched
                        //   formik.touched?.industry_custom_fields[index] && // Check if this specific field has been touched
                        //   formik.errors?.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                        //   formik.errors?.industry_custom_fields[index]?.name // Show error message for this specific field
                        // }
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id={`Email-${index}`}
                        label="Email"
                        name={`Email-${index}`}
                        placeholder="Email"
                        autoComplete={`Email-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]?.email
                        }
                        onChange={(e) =>
                          handleIndustryChange(index, "email", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        // error={
                        //   formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                        //   formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                        //   formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                        //   formik.errors.industry_custom_fields[index]?.email // Check if there's an error for this specific field
                        // }
                        // helperText={
                        //   formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                        //   formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                        //   formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                        //   formik.errors.industry_custom_fields[index]?.email // Show error message for this specific field
                        // }
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        margin="normal"
                        type="number"
                        fullWidth
                        id={`Contact-${index}`}
                        label="Contact"
                        name={`Contact-${index}`}
                        placeholder="Contact"
                        autoComplete={`Contact-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]?.contact
                        }
                        onChange={(e) =>
                          handleIndustryChange(index, "contact", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.contact // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.contact // Show error message for this specific field
                        }
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id={`OfficeAddress-${index}`}
                        label="Office Address"
                        name={`OfficeAddress-${index}`}
                        placeholder="Office Address"
                        autoComplete={`OfficeAddress-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]
                            ?.office_address
                        }
                        onChange={(e) =>
                          handleIndustryChange(
                            index,
                            "office_address",
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        // error={
                        //   formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                        //   formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                        //   formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                        //   formik.errors.industry_custom_fields[index]
                        //     ?.office_address // Check if there's an error for this specific field
                        // }
                        // helperText={
                        //   formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                        //   formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                        //   formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                        //   formik.errors.industry_custom_fields[index]
                        //     ?.office_address // Show error message for this specific field
                        // }
                      />
                    </Grid>

                    <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
                      <IconButton
                        color="primary"
                        onClick={() => handleIncreaseIndustryFields(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                      {formik.values.industry_custom_fields.length > 1 && (
                        <IconButton
                          color="secondary"
                          onClick={() => handleDecreaseIndustryFields(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                </Grid>
              ))}

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl
                  className="file-upload-style"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <Grid display={"flex"} sx={{ gap: 2 }}>
                    <Grid>
                      <CloudUploadIcon
                        style={{ cursor: "pointer", color: "#00bfff" }}
                        onClick={() => fileInputRef4.current.click()}
                      />
                      {formik?.values?.upload_doc ? (
                        <Link
                          to={
                            !checkVal
                              ? formik?.values?.upload_doc
                              : URL.createObjectURL(formik?.values?.upload_doc)
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VisibilityIcon />
                        </Link>
                      ) : (
                        <></>
                      )}
                      <Typography
                        sx={{ color: (theme) => theme.palette.text.primary }}
                        fontSize={14}
                      >
                        {formik.values.upload_doc_name}
                      </Typography>
                      <input
                        type="file"
                        accept=".pdf"
                        style={{ display: "none" }}
                        ref={fileInputRef4}
                        onChange={(event) =>
                          handleFileUpload(event, "upload_doc")
                        }
                      />
                      {formik.errors.upload_doc &&
                        formik.touched.upload_doc && (
                          <Typography color="error">
                            {formik.errors.upload_doc}
                          </Typography>
                        )}
                    </Grid>
                    <Grid>
                      <FormLabel>Upload Documents</FormLabel>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {/* <Box ml={2} display="inline-block"> */}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    endIcon={<PublishIcon />}
                    loading={loading}
                    loadingPosition="end"
                  >
                    <span>{Option ? "Update" : "Submit"}</span>
                  </LoadingButton>
                {/* </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default UpsertProgressReport;
