import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    "GET_ALL_ROLES",
    "GET_ALL_REGISTER_TYPES",
    "CREATE_USER",
    "LOGIN_USER",
    "INCUBATION",
    "POST_INCUBATION",
    "UPDATE_INCUBATION",
    //============>routes
    "ROUTES",
    "POST_ROUTES",
    "UPDATE_ROUTES",
    "PERMISSION",
    "SINGLE_PERMISSION",
    "CREATE_APPLICANT",
    "UPDATE_APPLICANT",
    "MPR",
    "POST_MPR",
    "UPDATE_INCUBATION_USER",
    "USER_MENU",
    "CATEGORY",
    "DELETE_CATEGORY",
    "POST_CATEGORY",
    "UPDATE_POST_CATEGORY",
    "POSTTYPE",
    "DELETE_POSTTYPE",
    "POST_POSTTYPE",
    "UPDATE_POSTTYPE",
    "SSO_LOGIN",
    "CUSTOM_FIELD",
    "GET_CustomPost",
    "CustomPost",
    "GET_PAYMENT_LIST",
    "POST_PAYMENT_LIST",
    "UPDATE_PAYMENT_LIST",
    "DELETE_PAYMENT_LIST",
    "GET_SECTOR",
    "DELETE_SECTOR",
    "GET_SUBSECTOR",
    "GET_LAC",
    "GET_PC",
    "GET_BET",
    "GET_PLATEFORM",
    "GET_PRIORITY",
    "GET_TEHSIL",
    "GET_DIST",
    "GET_SPECIALIZATION",
    "GET_INDUSTRY",
    "GET_APPLICATION",
    "GET_PROGRESSREPORT",
    "GET_MENTOR",
    "GET_SUBSECTOR",
    "AGENDA_LIST",
    "SELECTED_MENTOR_LIST",
    "STATE_DISTRICT",
    "DISTRICT_TEHSIL",
    "SINGLE_GRIEVANCES",
    "CLOSE_GRIEVANCES",
    "PublicDashboard_Grievance",
    "GET_GRIEVANCES",
    "CREATE_GRIEVANCES",
  ],
  endpoints: (builder) => ({
    getAllRoles: builder.query({
      query: (args) => ({
        url: `/roles`,
        params: { type: args },
        providesTags: ["GET_ALL_ROLES"],
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    getAllRegisterTypes: builder.query({
      query: () => "/register",
      providesTags: ["GET_ALL_REGISTER_TYPES"],
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "/user/createfrontenduser",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CREATE_USER"],
    }),
    resourceList: builder.query({
      query: (data) => ({
        url: `/user/getSource/User`,
        params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["INCUBATION"],
    }),
    loginUser: builder.mutation({
      query: (body) => ({
        url: "/user/login",
        method: "post",
        body,
      }),
      providesTags: ["LOGIN_USER"],
    }),
    loginUserOtp: builder.mutation({
      query: (body) => ({
        url: "/user/loginbyotp",
        method: "post",
        body,
      }),
      providesTags: ["LOGIN_USER"],
    }),
    incubationDetails: builder.query({
      query: (data) => ({
        url: `/incubation`,
        params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["INCUBATION"],
    }),

    singleIncubationData: builder.query({
      query: (id) => ({
        url: `/incubation/${id}`,
        // params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["INCUBATION"],
    }),
    createIncubation: builder.mutation({
      invalidatesTags: ["INCUBATION"],
      query: (body) => ({
        url: "/incubation",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_INCUBATION"],
    }),
    updateIncubation: builder.mutation({
      invalidatesTags: ["INCUBATION"],
      query: (body) => ({
        url: `/incubation/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteIncubation: builder.mutation({
      invalidatesTags: ["INCUBATION"],
      query: (id) => ({
        url: `/incubation/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // body,
      }),
      providesTags: ["POST_INCUBATION"],
    }),
    //=====> RoutesDetailsQuery
    incubationGetList: builder.query({
      query: (id) => ({
        url: `/incubation/getincubationbysubsector/${id}`,
        // params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["INCUBATION"],
    }),
    routesDetails: builder.query({
      invalidatesTags: ["USER_MENU"],
      query: (data) => ({
        url: "/route",
        params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["ROUTES", "USER_MENU"],
    }),
    createRoutes: builder.mutation({
      invalidatesTags: ["ROUTES"],
      query: (body) => ({
        url: "/route",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_ROUTES"],
    }),
    updateRoutes: builder.mutation({
      invalidatesTags: ["ROUTES"],
      query: (body) => ({
        url: `/route/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteRoutes: builder.mutation({
      invalidatesTags: ["INCUBATION", "USER_MENU"],
      query: (id) => ({
        url: `/route/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // body,
      }),
      providesTags: ["POST_ROUTES"],
    }),

    //=====> RolesDetailsQuery
    rolesDetails: builder.query({
      query: (data) => ({
        url: "/roles",
        params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["ROLES"],
    }),
    createRoles: builder.mutation({
      invalidatesTags: ["ROLES"],
      query: (body) => ({
        url: "/roles",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_ROLES"],
    }),
    updateRoles: builder.mutation({
      invalidatesTags: ["ROLES"],
      query: (body) => ({
        url: `/roles/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteRoles: builder.mutation({
      invalidatesTags: ["ROLES"],
      query: (id) => ({
        url: `/roles/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // body,
      }),
      providesTags: ["POST_ROLES"],
    }),

    //=====> PermissionDetailsQuery
    permissionDetails: builder.query({
      query: (data) => ({
        url: "/permission",
        params: { ...data },
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["PERMISSION"],
    }),
    createPermission: builder.mutation({
      invalidatesTags: ["PERMISSION"],
      query: (body) => ({
        url: "/permission",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_PERMISSION"],
    }),
    updatePermission: builder.mutation({
      invalidatesTags: ["PERMISSION"],
      query: (body) => ({
        url: `/permission/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    updateSingleDocStatus: builder.mutation({
      invalidatesTags: ["PERMISSION"],
      query: (body) => ({
        url: `/permission/updateSingleDocStatus/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deletePermission: builder.mutation({
      invalidatesTags: ["PERMISSION"],
      query: (id) => ({
        url: `/permission/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // body,
      }),
      providesTags: ["POST_PERMISSION"],
    }),
    singlePermission: builder.query({
      invalidatesTags: ["SINGLE_PERMISSION"],
      query: (id) => ({
        url: `/permission/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // body,
      }),
      providesTags: ["POST_PERMISSION"],
    }),
    //=================>applicant api's

    // createApplicant: builder.mutation({
    //   invalidatesTags: ["CREATE_APPLICANT"],
    //   query: (body) => ({
    //     url: `/applicant/?step=${body.id}`,
    //     method: body.payload.application_id ? "put" : "post",
    //     body: body.payload,
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    // }),
    // singleApplicant: builder.query({
    //   invalidatesTags: ["GET_APPLICANT"],
    //   query: (id) => ({
    //     url: `applicant/getSingleApplication/${id}`,
    //     method: "get",
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    // }),
    allApplicant: builder.query({
      invalidatesTags: ["GET_APPLICANT", "ApplicantStatus"],
      query: (id) => ({
        url: `applicant`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["ApplicantStatus"],
    }),
    uploadDoc: builder.mutation({
      invalidatesTags: ["GET_DOCUMENT"],
      query: (data) => ({
        url: `applicant/uploaddocument/?step=${"document"}`,
        method: "put",
        body: data,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    singleIncubation: builder.query({
      invalidatesTags: ["INCUBATION"],
      query: ({ param }) => ({
        url: `/roles?type=${param}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // body,
      }),
      providesTags: ["INCUBATION"],
    }),
    getListIncubation: builder.query({
      invalidatesTags: ["INCUBATION"],
      query: (data) => ({
        url: `user`,
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["INCUBATION"],
    }),
    getSingleIncubation: builder.query({
      invalidatesTags: ["INCUBATION"],
      query: (id) => ({
        url: `user/SingleIncuba/${id}`,
        method: "get",
        // body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["INCUBATION"],
    }),
    UpdateIncubationUser: builder.mutation({
      invalidatesTags: ["UPDATE_INCUBATION_USER", "INCUBATION"],
      query: (body) => ({
        url: `user/update/${body.id}`,
        method: "post",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteIncubationUser: builder.mutation({
      invalidatesTags: ["DELETE_USER", "INCUBATION"],
      query: (id) => ({
        url: `user/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DELETE_USER", "INCUBATION"],
    }),
    menuDetails: builder.query({
      invalidatesTags: ["USER_MENU", "ROUTES"],
      query: (param) => ({
        url: "/route/getusermenu",
        // params:{...data},
        method: "get",
        headers: {
          Authorization: param || localStorage.getItem("token"),
        },
      }),
      providesTags: ["USER_MENU", "ROUTES"],
    }),
    // updateApplicantStatus: builder.mutation({
    //   invalidatesTags: ["ApplicantStatus",'GET_APPLICANT'],
    //   query: (param) => ({
    //     url: `/applicant/updateApplictionStatus/${param?.id}`,
    //     body: param.payload,
    //     method: "put",
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    //   providesTags: ["ApplicantStatus"],
    // }),
    // Category api"s

    getCategory: builder.query({
      invalidatesTags: ["CATEGORY"],
      query: (data) => ({
        url: `/category`,
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CATEGORY"],
    }),
    createCategory: builder.mutation({
      invalidatesTags: ["POST_CATEGORY", "CATEGORY"],
      query: (body) => ({
        url: "/category",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_CATEGORY", "CATEGORY"],
    }),
    updateCategory: builder.mutation({
      invalidatesTags: ["UPDATE_POST_CATEGORY", "CATEGORY"],
      query: (body) => ({
        url: `/category/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteCategory: builder.mutation({
      invalidatesTags: ["DELETE_CATEGORY", "CATEGORY"],
      query: (id) => ({
        url: `category/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DELETE_CATEGORY", "CATEGORY"],
    }),

    //=========>postType api's

    getPostType: builder.query({
      invalidatesTags: ["POSTTYPE"],
      query: (data) => ({
        url: `/posttype`,
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POSTTYPE"],
    }),
    createPostType: builder.mutation({
      invalidatesTags: ["POST_POSTTYPE", "POSTTYPE"],
      query: (body) => ({
        url: "/posttype",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_POSTTYPE", "POSTTYPE"],
    }),
    updatePostType: builder.mutation({
      invalidatesTags: ["UPDATE_POSTTYPE", "POSTTYPE"],
      query: (body) => ({
        url: `/posttype/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    updatePostStatusType: builder.mutation({
      invalidatesTags: ["UPDATE_POSTTYPE", "POSTTYPE"],
      query: (body) => ({
        url: `/posttype/updatedocStatus/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deletePostType: builder.mutation({
      invalidatesTags: ["DELETE_POSTTYPE", "POSTTYPE"],
      query: (id) => ({
        url: `posttype/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DELETE_POSTTYPE", "POSTTYPE"],
    }),

    // ============================>ssologinapi
    ssoLogin: builder.mutation({
      invalidatesTags: ["SSO_LOGIN"],
      query: (token) => ({
        url: `user/getssouser`,
        method: "post",
        body: token,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["SSO_LOGIN"],
    }),

    //custom field api's

    getCustomField: builder.query({
      invalidatesTags: ["CUSTOM_FIELD"],
      query: (data) => ({
        url: `/customfield`,
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CUSTOM_FIELD"],
    }),
    getSingleCustomField: builder.query({
      invalidatesTags: ["CUSTOM_FIELD"],
      query: (id) => ({
        url: `/customfield/${id}`,
        method: "get",
        // body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CUSTOM_FIELD"],
    }),
    createCustomField: builder.mutation({
      invalidatesTags: ["POST_CUSTOM_FIELD", "CUSTOM_FIELD"],
      query: (body) => ({
        url: "/customfield",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["POST_CUSTOM_FIELD", "CUSTOM_FIELD"],
    }),
    updateCustomField: builder.mutation({
      invalidatesTags: ["UPDATE_CUSTOM_FIELD", "CUSTOM_FIELD"],
      query: (body) => ({
        url: `/customfield/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteCustomField: builder.mutation({
      invalidatesTags: ["DELETE_CUSTOM_FIELD", "CUSTOM_FIELD"],
      query: (id) => ({
        url: `customfield/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DELETE_CUSTOM_FIELD", "CUSTOM_FIELD"],
    }),

    //========>To get the data for CMS when i select Post type
    getCustomFieldPosttype: builder.query({
      invalidatesTags: ["CUSTOM_FIELD_POST_TYPE"],
      query: ({ id }) => ({
        url: `/customfield/getcustomField/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CUSTOM_FIELD_POST_TYPE"],
    }),

    //================>Cms

    getCms: builder.query({
      invalidatesTags: ["GET_CustomPost"],
      query: (data) => ({
        url: "/CustomPost",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_CustomPost"],
    }),
    createCms: builder.mutation({
      invalidatesTags: ["CustomPost", "GET_CustomPost"],
      query: (body) => ({
        url: "/CustomPost",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CustomPost", "GET_CustomPost"],
    }),
    updateCms: builder.mutation({
      invalidatesTags: ["UPDATE_CustomPost", "GET_CustomPost"],
      query: (body) => ({
        url: `/CustomPost/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    singleCms: builder.query({
      invalidatesTags: ["SINGLE_CustomPost"],
      query: (id) => ({
        url: `/CustomPost/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteCms: builder.mutation({
      invalidatesTags: ["DELETE_CustomPost", "GET_CustomPost"],
      query: (id) => ({
        url: `CustomPost/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DELETE_CustomPost", "GET_CustomPost"],
    }),

    //when user login then to get the data of the applicant
    applicationDetail: builder.query({
      invalidatesTags: ["APPLICATION_DETAIL"],
      query: (token) => ({
        url: `/user/me`,
        method: "get",
        headers: {
          Authorization: token || localStorage.getItem("token"),
        },
      }),
      providesTags: ["APPLICATION_DETAIL"],
    }),

    //sector==============>
    getSector: builder.query({
      invalidatesTags: ["GET_SECTOR"],
      query: (data) => ({
        url: "/sector",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SECTOR"],
    }),
    createSector: builder.mutation({
      invalidatesTags: ["GET_SECTOR"],
      query: (body) => ({
        url: "/sector",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SECTOR"],
    }),
    updateSector: builder.mutation({
      invalidatesTags: ["GET_SECTOR"],
      query: (body) => ({
        url: `/sector/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteSector: builder.mutation({
      invalidatesTags: ["GET_SECTOR"],
      query: (id) => ({
        url: `sector/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SECTOR"],
    }),

    //LAC==============>
    getLegislative: builder.query({
      invalidatesTags: ["GET_LAC"],
      query: (data) => ({
        url: "/lac",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_LAC"],
    }),
    getLegislativeDistrictWise: builder.query({
      invalidatesTags: ["STATE_DISTRICT"],
      query: (id) => ({
        url: `/lac/getlacdistrict/${id}`,
        method: "get",
        // body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["STATE_DISTRICT"],
    }),
    createLegislative: builder.mutation({
      invalidatesTags: ["GET_LAC"],
      query: (body) => ({
        url: "/lac",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_LAC"],
    }),
    updateLegislative: builder.mutation({
      invalidatesTags: ["GET_LAC"],
      query: (body) => ({
        url: `/lac/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteLegislative: builder.mutation({
      invalidatesTags: ["GET_LAC"],
      query: (id) => ({
        url: `lac/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_LAC"],
    }),

    //subsector==============>
    getSubSector: builder.query({
      invalidatesTags: ["GET_SUBSECTOR"],
      query: (data) => ({
        url: "/subsector",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SUBSECTOR"],
    }),
    createSubSector: builder.mutation({
      invalidatesTags: ["GET_SUBSECTOR"],
      query: (body) => ({
        url: "/subsector",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SUBSECTOR"],
    }),
    updateSubSector: builder.mutation({
      invalidatesTags: ["GET_SUBSECTOR"],
      query: (body) => ({
        url: `/subsector/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SUBSECTOR"],
    }),
    deleteSubSector: builder.mutation({
      invalidatesTags: ["GET_SUBSECTOR"],
      query: (id) => ({
        url: `subsector/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SUBSECTOR"],
    }),

    //parliamentconstituency==============>
    getPC: builder.query({
      invalidatesTags: ["GET_PC"],
      query: (data) => ({
        url: "/parliamentconstituency/getparliament",
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PC"],
    }),
    getParlimentContiWise: builder.query({
      invalidatesTags: ["GET_PC"],
      query: (id) => ({
        url: `/parliamentconstituency/getconstituency/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PC"],
    }),
    // getLegislativeDistrictWise: builder.query({
    //   invalidatesTags: ["STATE_DISTRICT"],
    //   query: (id) => ({
    //     url: `/lac/getlacdistrict/${id}`,
    //     method: "get",
    //     // body: body.payload,
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    //   providesTags: ["STATE_DISTRICT"],
    // }),
    createPC: builder.mutation({
      invalidatesTags: ["GET_PC"],
      query: (body) => ({
        url: "/parliamentconstituency",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PC"],
    }),
    updatePC: builder.mutation({
      invalidatesTags: ["GET_PC"],
      query: (body) => ({
        url: `/parliamentconstituency/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deletePC: builder.mutation({
      invalidatesTags: ["GET_PC"],
      query: (id) => ({
        url: `parliamentconstituency/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PC"],
    }),

    //businessentitytype==============>
    getBET: builder.query({
      invalidatesTags: ["GET_BET"],
      query: (data) => ({
        url: "/businessentitytype",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BET"],
    }),
    createBET: builder.mutation({
      invalidatesTags: ["GET_BET"],
      query: (body) => ({
        url: "/businessentitytype",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BET"],
    }),
    updateBET: builder.mutation({
      invalidatesTags: ["GET_BET"],
      query: (body) => ({
        url: `/businessentitytype/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deleteBET: builder.mutation({
      invalidatesTags: ["GET_BET"],
      query: (id) => ({
        url: `businessentitytype/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BET"],
    }),
    //platform==============>
    getPlatform: builder.query({
      invalidatesTags: ["GET_PLATEFORM"],
      query: (data) => ({
        url: "/platform",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PLATEFORM"],
    }),
    createPlatform: builder.mutation({
      invalidatesTags: ["GET_PLATEFORM"],
      query: (body) => ({
        url: "/platform",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PLATEFORM"],
    }),
    updatePlatform: builder.mutation({
      invalidatesTags: ["GET_PLATEFORM"],
      query: (body) => ({
        url: `/platform/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deletePlatform: builder.mutation({
      invalidatesTags: ["GET_PLATEFORM"],
      query: (id) => ({
        url: `platform/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PLATEFORM"],
    }),
    //opt==============>
    postOTP: builder.mutation({
      invalidatesTags: ["GET_OTP"],
      query: (data) => ({
        url: "/otp",
        method: "post",
        body: data,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_OTP"],
    }),
    verifyOTP: builder.mutation({
      invalidatesTags: ["GET_OTP"],
      query: (body) => ({
        url: "otp/verifyotp",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_OTP"],
    }),
    // updatePlatform: builder.mutation({
    //   invalidatesTags: ["GET_PLATEFORM"],
    //   query: (body) => ({
    //     url: `/platform/${body.id}`,
    //     method: "put",
    //     body: body.payload,
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    // }),
    // deletePlatform: builder.mutation({
    //   invalidatesTags: ["GET_PLATEFORM"],
    //   query: (id) => ({
    //     url: `platform/${id}`,
    //     method: "delete",
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    //   providesTags: ["GET_PLATEFORM"],
    // }),

    //platform==============>
    getPriority: builder.query({
      invalidatesTags: ["GET_PRIORITY"],
      query: (data) => ({
        url: "/priority",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PRIORITY"],
    }),
    createPriority: builder.mutation({
      invalidatesTags: ["GET_PRIORITY"],
      query: (body) => ({
        url: "/priority",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PRIORITY"],
    }),
    updatePriority: builder.mutation({
      invalidatesTags: ["GET_PRIORITY"],
      query: (body) => ({
        url: `/priority/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    deletePriority: builder.mutation({
      invalidatesTags: ["GET_PRIORITY"],
      query: (id) => ({
        url: `priority/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PRIORITY"],
    }),

    //tehsil==============>
    getTehsil: builder.query({
      invalidatesTags: ["GET_TEHSIL"],
      query: (data) => ({
        url: "/teshil",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_TEHSIL"],
    }),
    createTehsil: builder.mutation({
      invalidatesTags: ["GET_TEHSIL"],
      query: (body) => ({
        url: "/teshil",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_TEHSIL"],
    }),
    updateTehsil: builder.mutation({
      invalidatesTags: ["GET_TEHSIL"],
      query: (body) => ({
        url: `/teshil/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_TEHSIL"],
    }),
    deleteTehsil: builder.mutation({
      invalidatesTags: ["GET_TEHSIL"],
      query: (id) => ({
        url: `/teshil/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_TEHSIL"],
    }),

    //Block==============>
    getBlock: builder.query({
      invalidatesTags: ["GET_BLOCK"],
      query: (data) => ({
        url: "/block",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BLOCK"],
    }),
    createBlock: builder.mutation({
      invalidatesTags: ["GET_BLOCK"],
      query: (body) => ({
        url: "/block",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BLOCK"],
    }),
    updateBlock: builder.mutation({
      invalidatesTags: ["GET_BLOCK"],
      query: (body) => ({
        url: `/block/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BLOCK"],
    }),
    deleteBlock: builder.mutation({
      invalidatesTags: ["GET_BLOCK"],
      query: (id) => ({
        url: `block/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_BLOCK"],
    }),
    getState: builder.query({
      invalidatesTags: ["GET_STATE"],
      query: (data) => ({
        url: "/state",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_STATE"],
    }),
    createState: builder.mutation({
      invalidatesTags: ["STATE_CREATE", "GET_STATE"],
      query: (body) => ({
        url: "/state",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["STATE_CREATE", "GET_STATE"],
    }),
    updateState: builder.mutation({
      invalidatesTags: ["STATE_CREATE", "GET_STATE"],
      query: (body) => ({
        url: `/state/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["STATE_CREATE", "GET_STATE"],
    }),
    deleteState: builder.mutation({
      invalidatesTags: ["STATE_CREATE", "GET_STATE"],
      query: (id) => ({
        url: `state/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["STATE_CREATE", "GET_STATE"],
    }),
    getDistrict: builder.query({
      invalidatesTags: ["GET_DIST", "GET_APPLICATION"],
      query: (data) => ({
        url: "/district",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_DIST", "GET_APPLICATION"],
    }),
    getParliamentDistrict: builder.query({
      invalidatesTags: ["GET_DIST"],
      query: (data) => ({
        url: "/parliamentconstituency/getdistrict",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_DIST"],
    }),
    createDistrict: builder.mutation({
      invalidatesTags: ["GET_DIST"],
      query: (body) => ({
        url: "/district",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_DIST"],
    }),
    updateDistrict: builder.mutation({
      invalidatesTags: ["GET_DIST"],
      query: (body) => ({
        url: `/district/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_DIST"],
    }),
    deleteDistrict: builder.mutation({
      invalidatesTags: ["GET_DIST"],
      query: (id) => ({
        url: `district/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_DIST"],
    }),

    // +++++++++++++>Applicant Api
    getApplication: builder.query({
      invalidatesTags: ["GET_APPLICATION","Select_Mentor_LIST","GET_APPLICANT","SelectMentor_LIST","GET_PROGRESSREPORT"],
      query: (data) => ({
        url: "/newapplication",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    getOldApplication: builder.query({
      invalidatesTags: ["GET_OLD_APPLICATION","Select_Mentor_LIST","GET_APPLICANT","SelectMentor_LIST","GET_PROGRESSREPORT"],
      query: (data) => ({
        url: "/newapplication/old",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_OLD_APPLICATION"],
    }),
    createApplicant: builder.mutation({
      invalidatesTags: ["GET_APPLICANT"],
      query: (body) => ({
        url: `/newapplication?step=${body.step}`,
        method: "post",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICANT"],
    }),
    singleApplicant: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/newapplication/getSingleApplication/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    deleteApplicant: builder.mutation({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/newapplication/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    deleteReco: builder.mutation({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/recognition/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),

    changeStatusByNodal: builder.mutation({
      invalidatesTags: ["GET_APPLICATION", "AGENDA_LIST"],
      query: (body) => ({
        url: `/newapplication/changestatusbynodal/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    }),
    trackApplicationStatus: builder.query({
      invalidatesTags: ["GET_APPLICATION"], 
      query: (id) => ({
        url: `/newapplication/trackstatus/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    trackApplicationLogs: builder.query({
      invalidatesTags: ["GET_LOGS"],
      query: (data) => ({
        url: `/logs/${data?.id}/${data?.type}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_LOGS"],
    }),
    singleApplicationLog: builder.query({
      invalidatesTags: ["GET_LOG"],
      query: (id) => ({
        url: `/logs/singlelogs/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_LOG"],
    }),
    trackProgressReportStatus: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/progressreport/trackstatus/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    trackProgressReportTaskCompleteStatus: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/progressreport/trackpr/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    trackMentorStatus: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/mentorapplication/trackstatus/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    trackRecognitionStatus: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/recognition/trackstatus/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    generateRecognitionCertificate: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/recognition/certificate/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    generateApplicationCertificate: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/newapplication/registercertificate/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),

    changeStatusByHOD: builder.mutation({
      invalidatesTags: ["GET_APPLICATION"],
      query: (body) => ({
        url: `/mentorapplication/changestatusbyhod/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    changeStatusByTechnical: builder.mutation({
      invalidatesTags: ["GET_APPLICATION", "AGENDA_LIST"],
      query: (body) => ({
        url: `/newapplication/changestatusbytechnicals/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    }),
    changeStatusByMentor: builder.mutation({
      invalidatesTags: ["GET_APPLICATION", "AGENDA_LIST"],
      query: (body) => ({
        url: `/newapplication/changestatusbymentor/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    }),
    // changeStatusByDic: builder.mutation({
    //   invalidatesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    //   query: (body) => ({
    //     url: `/newapplication/changestatusbydic/${body.id}`,
    //     method: "put",
    //     body: body.payload,
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    //   providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    // }),
    changeApplicationStatusByHod: builder.mutation({
      invalidatesTags: ["GET_APPLICATION", "AGENDA_LIST"],
      query: (body) => ({
        url: `/newapplication/changestatusbydirectorate/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    }),
    changeRecognitionStatusByDic: builder.mutation({
      invalidatesTags: ["GET_RECOGNITION", "AGENDA_LIST"],
      query: (body) => ({
        url: `/recognition/changestatusbydic/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    }),
    changeRecognitionStatusByDirector: builder.mutation({
      invalidatesTags: ["GET_RECOGNITION", "AGENDA_LIST"],
      query: (body) => ({
        url: `/recognition/changestatusbydirectorate/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION", "AGENDA_LIST"],
    }),

    getIncubation: builder.query({
      invalidatesTags: ["GET_APPLICANT"],
      query: () => ({
        url: `/incubation`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICANT"],
    }),

    //=============GET_SPECIALIZATION
    getSpecialization: builder.query({
      invalidatesTags: ["GET_SPECIALIZATION"],
      query: (data) => ({
        url: "/specialization",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SPECIALIZATION"],
    }),
    createSpecialization: builder.mutation({
      invalidatesTags: ["GET_SPECIALIZATION"],
      query: (body) => ({
        url: "/specialization",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SPECIALIZATION"],
    }),
    updateSpecialization: builder.mutation({
      invalidatesTags: ["GET_SPECIALIZATION"],
      query: (body) => ({
        url: `/specialization/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SPECIALIZATION"],
    }),
    deleteSpecialization: builder.mutation({
      invalidatesTags: ["GET_SPECIALIZATION"],
      query: (id) => ({
        url: `specialization/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_SPECIALIZATION"],
    }),

    //=============GET_INDUSTRY
    getIndustry: builder.query({
      invalidatesTags: ["GET_INDUSTRY"],
      query: (data) => ({
        url: "/industry",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_INDUSTRY"],
    }),
    createIndustry: builder.mutation({
      invalidatesTags: ["GET_INDUSTRY"],
      query: (body) => ({
        url: "/industry",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_INDUSTRY"],
    }),
    updateIndustry: builder.mutation({
      invalidatesTags: ["GET_INDUSTRY"],
      query: (body) => ({
        url: `/industry/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_INDUSTRY"],
    }),
    deleteIndustry: builder.mutation({
      invalidatesTags: ["GET_INDUSTRY"],
      query: (id) => ({
        url: `industry/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_INDUSTRY"],
    }),

    //=============GET_/startupstage
    getStartUpStage: builder.query({
      invalidatesTags: ["GET_STARTUPSTAGE"],
      query: (data) => ({
        url: "/startupstage",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_STARTUPSTAGE"],
    }),
    createStartUpStage: builder.mutation({
      invalidatesTags: ["GET_STARTUPSTAGE"],
      query: (body) => ({
        url: "/startupstage",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_STARTUPSTAGE"],
    }),
    updateStartUpStage: builder.mutation({
      invalidatesTags: ["GET_STARTUPSTAGE"],
      query: (body) => ({
        url: `/startupstage/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_STARTUPSTAGE"],
    }),
    deleteStartUpStage: builder.mutation({
      invalidatesTags: ["GET_STARTUPSTAGE"],
      query: (id) => ({
        url: `/startupstage/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_STARTUPSTAGE"],
    }),
    //=============GET_PROGRESSREPORT
    getProgressReport: builder.query({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (data) => ({
        url: "/progressreport",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),
    getProgressReportById: builder.query({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (id) => ({
        url: `/progressreport/progressreportbyapplicationid/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),
    createProgressReport: builder.mutation({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (body) => ({
        url: "/progressreport",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),

    updateProgressReport: builder.mutation({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (body) => ({
        url: `/progressreport/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),
    singleProgressReport: builder.query({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (id) => ({
        url: `/progressreport/singleprogress/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),
    deleteProgressReport: builder.mutation({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (id) => ({
        url: `/progressreport/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),

    //file upload
    uploadDetailFile: builder.mutation({
      invalidatesTags: ["GET_DETAIL_FILE"],
      query: (body) => ({
        url: "/progressreport/fileupload",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_DETAIL_FILE"],
    }),
    updateProgressReportStatus: builder.mutation({
      invalidatesTags: ["GET_PROGRESSREPORT"],
      query: (body) => ({
        url: `/progressreport/status/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_PROGRESSREPORT"],
    }),

    changePrStatusByMentor: builder.mutation({
      query: (body) => ({
        url: `/progressreport/updatestatusbymantor/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    changePrStatusByIncubation: builder.mutation({
      query: (body) => ({
        url: `/progressreport/updatestatusbynodal/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    changePrStatusByDic: builder.mutation({
      //=================>still needa the url endpoints
      query: (body) => ({
        url: `/progressreport/updatestatusbydic/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    changePrStatusByHOD: builder.mutation({
      //=================>still needa the url endpoints
      query: (body) => ({
        url: `/progressreport/updatestatusbydirectorate/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),

    //=======================> Mentor api's

    getMentor: builder.query({
      invalidatesTags: ["GET_MENTOR"],
      query: (data) => ({
        url: "/mentorapplication",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_MENTOR"],
    }),
    singleMentor: builder.query({
      invalidatesTags: ["GET_MENTOR"],
      query: (id) => ({
        // url: "/mentorapplication",
        url: `/mentorapplication/singlementor/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_MENTOR"],
    }),
    createMentor: builder.mutation({
      invalidatesTags: ["GET_MENTOR"],
      query: (body) => ({
        url: "/mentorapplication",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_MENTOR"],
    }),
    updateMentor: builder.mutation({
      invalidatesTags: ["GET_MENTOR"],
      query: (body) => ({
        url: `/mentorapplication/${body.id}`,
        method: "put",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_MENTOR"],
    }),
    deleteMentor: builder.mutation({
      invalidatesTags: ["GET_MENTOR"],
      query: (id) => ({
        url: `/mentorapplication/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_MENTOR"],
    }),

    singleGrievances: builder.query({
      // invalidatesTags: ["GET_GRIEVANCES"],
      query: (id) => ({
        url: `/grievance/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      // providesTags: ["GET_GRIEVANCES"],
    }),
    closeGrievances: builder.query({
      invalidatesTags: ["GET_GRIEVANCES","CLOSE_GRIEVANCES"],
      query: (id) => ({
        url: `/grievance/grievanceclose/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CLOSE_GRIEVANCES","GET_GRIEVANCES"],
    }),

    getGrievances: builder.query({
      invalidatesTags: ["GET_GRIEVANCES","CLOSE_GRIEVANCES"],
      query: (data) => ({
        url: "/grievance",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_GRIEVANCES","CLOSE_GRIEVANCES"],
    }),
    // singleMentor: builder.query({
    //   invalidatesTags: ["GET_MENTOR"],
    //   query: (id) => ({
    //     // url: "/mentorapplication",
    //     url: `/mentorapplication/singlementor/${id}`,
    //     method: "get",
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    //   providesTags: ["GET_MENTOR"],
    // }),
    createGrievances: builder.mutation({
      // invalidatesTags: ["GET_GRIEVANCES"],
      query: (body) => ({
        url: "/user/grievance",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      // providesTags: ["GET_GRIEVANCES"],
    }),
    revertGrievances: builder.mutation({
      // invalidatesTags: ["REVERT_GRIEVANCES","SINGLE_GRIEVANCES"],
      query: (body) => ({
        url: "/grievance/revert",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      // providesTags: ["REVERT_GRIEVANCES","SINGLE_GRIEVANCES"],
    }),
    singleRevertGrievances: builder.query({
      // invalidatesTags: ["SINGLE_GRIEVANCES" ],
      query: (id) => ({
        url: `/grievance/revert/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      // providesTags: ["SINGLE_GRIEVANCES"],
    }),

    getPublicDashboardGrievance: builder.query({
      invalidatesTags: ["PublicDashboard_Grievance"],
      query: (body) => ({
        url: "/grievance/totalgrievance/data",
        method: "get",
        // body,
        // headers: {
        //   Authorization: localStorage.getItem("token"),
        // },
      }),
      providesTags: ["PublicDashboard_Grievance"],
    }),

    createProtectedGrievances: builder.mutation({
      invalidatesTags: ["GET_GRIEVANCES", "CREATE_GRIEVANCES"],
      query: (body) => ({
        url: `/grievance`,
        method: "post",
        body: body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_GRIEVANCES", "CREATE_GRIEVANCES"],
    }),
    deleteGrievances: builder.mutation({
      invalidatesTags: ["GET_GRIEVANCES"],
      query: (id) => ({
        url: `/grievance/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_GRIEVANCES"],
    }),

    //=============GET_Agenda
    agendaList: builder.query({
      invalidatesTags: ["AGENDA_LIST"],
      query: (body) => ({
        url: "/newapplication/?application_stage=3",
        method: "get",
        params: { ...body },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["AGENDA_LIST"],
    }),
    agendaRecordDelete: builder.mutation({
      invalidatesTags: ["AGENDA_LIST"],
      query: (id) => ({
        url: `/newapplication/deleteProceedingApplication/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["AGENDA_LIST"],
    }),
    createAgenda: builder.mutation({
      invalidatesTags: ["CREATE_AGENDA", "AGENDA_LIST"],
      query: (body) => ({
        url: "/agenda",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CREATE_AGENDA", "AGENDA_LIST"],
    }),
    getAgendaList: builder.query({
      invalidatesTags: ["AGENDA_LIST"],
      query: (body) => ({
        url: "/agenda/getincubationagenda",
        method: "get",
        params: { ...body },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["AGENDA_LIST"],
    }),
    deleteAgenda: builder.mutation({
      invalidatesTags: ["DELETE_AGENDA", "AGENDA_LIST"],
      query: (id) => ({
        url: `/agenda/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DELETE_AGENDA", "AGENDA_LIST"],
    }),
    getFilterAgendaList: builder.query({
      invalidatesTags: ["AGENDA_LIST"],
      query: (body) => ({
        url: `/agenda/${body?.id ? body?.id : ""}`,
        method: "get",
        // body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["AGENDA_LIST"],
    }),

    uploadAgendaDoc: builder.mutation({
      invalidatesTags: ["AGENDA_LIST"],
      query: (body) => ({
        url: `/agenda/uploadmom/${body.id}`,
        method: "put",
        body: body.parms,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["AGENDA_LIST"],
    }),

    //=============we will change it later==============
    selectedMentorList: builder.query({
      query: (body) => ({
        url: "/mentorapplication",
        method: "get",
        // body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["SELECTED_MENTOR_LIST"],
    }),
    selectMentor: builder.mutation({
      invalidatesTags: ["SelectMentor_LIST"],
      query: (body) => ({
        url: `/newapplication/selectmentor/${body.id}`,
        method: "put",
        body: body.value,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["Select_Mentor_LIST"],
    }),

    getDistrictStateWise: builder.query({
      invalidatesTags: ["STATE_DISTRICT"],
      query: (id) => ({
        url: `/state/getdistrict/${id}`,
        method: "get",
        // body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["STATE_DISTRICT"],
    }),
    getParliamentDistrictWise: builder.query({
      invalidatesTags: ["STATE_DISTRICT"],
      query: (id) => ({
        url: `/parliamentconstituency/getparliamentdistrict/${id}`,
        method: "get",
        // body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["STATE_DISTRICT"],
    }),

    getTehsilDistrictWise: builder.query({
      invalidatesTags: ["DISTRICT_TEHSIL"],
      query: (id) => ({
        url: `/district/gettehsil-block/${id}`,
        method: "get",
        // body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["DISTRICT_TEHSIL"],
    }),

    // +++++++++++++>Recognitation Api
    getRecognition: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (data) => ({
        url: "/recognition",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),

    createRecognition: builder.mutation({
      invalidatesTags: ["GET_APPLICANT"],
      query: (body) => ({
        url: `/recognition?step=${body.step}`,
        method: "post",
        body: body.payload,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICANT"],
    }),
    singleRecognition: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/recognition/getSingle/${id}`,
        method: "get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    deleteRecognition: builder.mutation({
      invalidatesTags: ["GET_APPLICATION"],
      query: (id) => ({
        url: `/recognition/${id}`,
        method: "delete",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    //====================getDICList:
    getDICList: builder.query({
      invalidatesTags: ["GET_APPLICATION"],
      query: (data) => ({
        url: "/dic",
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["GET_APPLICATION"],
    }),
    // ===========>
    copyToRecognition: builder.query({
      invalidatesTags: ["SINGLE_GRIEVANCES", "GET_APPLICATION"],
      query: (id) => ({
        url: `/recognition/copytorecognition/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["SINGLE_GRIEVANCES", "GET_APPLICATION"],
    }),
    createProfile: builder.mutation({
      invalidatesTags: ["Profile"],
      query: (body) => ({
        url: "/profile",
        method: "post",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["Profile"],
    }),
    getProfile: builder.query({
      invalidatesTags: ["Profile"],
      query: (body) => ({
        url: "/profile/getProfile",
        method: "get",
        body,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["Profile"],
    }),

    // updateProfile: builder.mutation({
    //   invalidatesTags: ["Profile"],
    //   query: (body) => ({
    //     url: `/profile/${body.id}`,
    //     method: "put",
    //     body: body.payload,
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //     },
    //   }),
    //   providesTags: ["Profile"],
    // }),
    getTotalGrievanceData: builder.query({
      query: (data) => ({
        url: "/grievance/totalhomepage/data",
        method: "get",
        params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),
    trackGrievance: builder.query({
      invalidatesTags: ["TRACK_GRIEVANCES"],
      query: (id) => ({
        url: `/grievance/trackstatus/${id}`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["TRACK_GRIEVANCES"],
    }),
    barChartLogs: builder.query({
      invalidatesTags: ["CHART_LOGS"],
      query: (id) => ({
        url: `/grievance/totalgraph/data`,
        method: "get",
        // params: { ...data },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
      providesTags: ["CHART_LOGS"],
    }),
  }),
});

export const {
  useGetAllRolesQuery,
  useGetAllRegisterTypesQuery,
  useCreateUserMutation,
  useLoginUserMutation,
  useLoginUserOtpMutation,
  //=====>Incubanation
  useIncubationDetailsQuery,
  useCreateIncubationMutation,
  useUpdateIncubationMutation,
  useDeleteIncubationMutation,
  useIncubationGetListQuery,
  useSingleIncubationDataQuery,
  //=====> RoutesDetailsQuery
  useRoutesDetailsQuery,
  useCreateRoutesMutation,
  useUpdateRoutesMutation,
  useDeleteRoutesMutation,
  //=====> RolesDetailsQuery
  useRolesDetailsQuery,
  useCreateRolesMutation,
  useUpdateRolesMutation,
  useDeleteRolesMutation,
  //=====> PermissionDetailsQuery
  usePermissionDetailsQuery,
  useSinglePermissionQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useUpdateSingleDocStatusMutation,
  useDeletePermissionMutation,
  //=====> ApplicantApi

  useUploadDocMutation,
  useAllApplicantQuery,
  //=====> MPRApi

  //  =====================================>
  useSingleIncubationQuery,
  useGetListIncubationQuery,
  useGetSingleIncubationQuery,
  useUpdateIncubationUserMutation,
  useDeleteIncubationUserMutation,
  // List of menu's api
  useMenuDetailsQuery,
  // useUpdateApplicantStatusMutation,

  //Category api's
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,

  //Category api's
  useGetPostTypeQuery,
  useCreatePostTypeMutation,
  useUpdatePostTypeMutation,
  useDeletePostTypeMutation,
  useUpdatePostStatusTypeMutation,

  //SSO api's
  useSsoLoginMutation,

  //Custom Field api's hook
  useGetCustomFieldQuery,
  useCreateCustomFieldMutation,
  useDeleteCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useGetSingleCustomFieldQuery,
  useGetCustomFieldPosttypeQuery,

  //CMS api's hook
  useGetCmsQuery,
  useCreateCmsMutation,
  useUpdateCmsMutation,
  useSingleCmsQuery,
  useDeleteCmsMutation,

  useApplicationDetailQuery,

  //Sector api's hook
  useGetSectorQuery,
  useCreateSectorMutation,
  useUpdateSectorMutation,
  useDeleteSectorMutation,

  //Sub Sector api's hook
  useGetSubSectorQuery,
  useCreateSubSectorMutation,
  useUpdateSubSectorMutation,
  useDeleteSubSectorMutation,

  //LAC api's hook
  useGetLegislativeQuery,
  useCreateLegislativeMutation,
  useUpdateLegislativeMutation,
  useDeleteLegislativeMutation,

  //PC api's hook
  useGetPCQuery,
  useCreatePCMutation,
  useUpdatePCMutation,
  useDeletePCMutation,

  //businessentitytype api's hook
  useGetBETQuery,
  useCreateBETMutation,
  useUpdateBETMutation,
  useDeleteBETMutation,

  //Platform api's hook
  useGetPlatformQuery,
  useCreatePlatformMutation,
  useUpdatePlatformMutation,
  useDeletePlatformMutation,

  //OTP api's hook
  usePostOTPMutation,
  useVerifyOTPMutation,
  // useCreatePlatformMutation,
  // useUpdatePlatformMutation,
  // useDeletePlatformMutation,

  // GET_Priority api's hook
  useGetPriorityQuery,
  useCreatePriorityMutation,
  useUpdatePriorityMutation,
  useDeletePriorityMutation,

  //Tehsil
  useGetTehsilQuery,
  useCreateTehsilMutation,
  useUpdateTehsilMutation,
  useDeleteTehsilMutation,

  //State api's hook
  useGetStateQuery,
  useCreateStateMutation,
  useUpdateStateMutation,
  useDeleteStateMutation,

  //District api's hook
  useGetDistrictQuery,
  useGetParliamentDistrictQuery,
  useCreateDistrictMutation,
  useUpdateDistrictMutation,
  useDeleteDistrictMutation,

  useGetParliamentDistrictWiseQuery,
  //Block api's hook
  useGetBlockQuery,
  useCreateBlockMutation,
  useUpdateBlockMutation,
  useDeleteBlockMutation,

  //GET_SPECIALIZATION api's hook
  useGetSpecializationQuery,
  useCreateSpecializationMutation,
  useUpdateSpecializationMutation,
  useDeleteSpecializationMutation,

  //GET_INDUSTRY api's hook
  useGetIndustryQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,

  useGetIncubationQuery,
  useCreateApplicantMutation,
  useDeleteApplicantMutation,
  useGetApplicationQuery,
  useGetOldApplicationQuery,
  useSingleApplicantQuery,

  //Applicant manage status by department
  useChangeStatusByNodalMutation,
  useChangeStatusByTechnicalMutation,
  useChangeStatusByMentorMutation,
  // useChangeStatusByDicMutation,
  useChangeStatusByHODMutation,

  useChangeRecognitionStatusByDicMutation,
  useChangeRecognitionStatusByDirectorMutation,
  useChangeApplicationStatusByHodMutation,

  //GET_StartUpStage api's hook
  useGetStartUpStageQuery,
  useCreateStartUpStageMutation,
  useUpdateStartUpStageMutation,
  useDeleteStartUpStageMutation,
  //GET_Mentor api's hook
  useGetMentorQuery,
  useCreateMentorMutation,
  useSingleMentorQuery,
  useUpdateMentorMutation,
  useDeleteMentorMutation,
  //GET_StartUpStage api's hook
  useGetProgressReportQuery,
  useGetProgressReportByIdQuery,
  useSingleProgressReportQuery,
  useCreateProgressReportMutation,
  useUpdateProgressReportMutation,
  useDeleteProgressReportMutation,
  useUpdateProgressReportStatusMutation,
  // ====status cahnge phase
  useChangePrStatusByMentorMutation,
  useChangePrStatusByIncubationMutation,
  useChangePrStatusByDicMutation,
  useChangePrStatusByHODMutation,

  //-------------->uploadDetailFile
  useUploadDetailFileMutation,
  // useGetProgressReportQuery,
  // <============== Agenda list==================>>
  useAgendaListQuery,
  useCreateAgendaMutation,
  useGetAgendaListQuery,
  useUploadAgendaDocMutation,
  useSelectedMentorListQuery,
  useSelectMentorMutation,
  useGetFilterAgendaListQuery,
  useDeleteAgendaMutation,
  useAgendaRecordDeleteMutation,

  //GET_Grievances api's hook
  useSingleGrievancesQuery,
  useCloseGrievancesQuery,
  useGetGrievancesQuery,
  useCreateGrievancesMutation,
  useRevertGrievancesMutation,
  useCreateProtectedGrievancesMutation,
  useDeleteGrievancesMutation,
  useGetPublicDashboardGrievanceQuery,

  useGetDistrictStateWiseQuery,
  useGetTehsilDistrictWiseQuery,

  // ========================Recognition api hook's============
  useDeleteRecoMutation,
  useCreateRecognitionMutation,
  useDeleteRecognitionMutation,
  useGetRecognitionQuery,
  useSingleRecognitionQuery,
  useGetDICListQuery,
  useCopyToRecognitionQuery,

  useCreateProfileMutation,
  useGetProfileQuery,
  //dic api
  useResourceListQuery,
  useGetTotalGrievanceDataQuery,
  useBarChartLogsQuery,
  useTrackApplicationLogsQuery
} = api;
