import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PersonIcon from "@mui/icons-material/Person";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PaymentIcon from "@mui/icons-material/Payment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CategoryIcon from "@mui/icons-material/Category";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import Hidden from "@mui/material/Hidden";
import PeopleIcon from "@mui/icons-material/People";
import GroupIcon from "@mui/icons-material/Groups2";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SettingsIcon from "@mui/icons-material/Settings";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FactoryIcon from "@mui/icons-material/Factory";
import ApprovalIcon from "@mui/icons-material/Approval";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import Face5Icon from "@mui/icons-material/Face5";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import EventIcon from "@mui/icons-material/Event";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import Face6Icon from "@mui/icons-material/Face6";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
export const iconToRender = (name) => {
    switch (name) {
      case "Dashboard":
        return <DashboardIcon />;
      case "Routes":
        return <LocationOnIcon />;
      case "Application":
        return <DashboardIcon />;
      case "Incubation center":
        return <ViewInArIcon />;
      case "Permissions":
        return <LockPersonIcon />;
      case "Payment Logs":
        return <SummarizeIcon />;
      case "Roles":
        return <ManageAccountsIcon />;
      case "User":
        return <PersonIcon />;
      case "CMS":
        return <SettingsIcon />;
      case "Payment":
        return <PaymentIcon />;
      case "Application status":
        return <AssignmentIcon />;
      case "Category":
        return <CategoryIcon />;
      case "Custom Field":
        return <TextFieldsIcon />;
      case "Post Type":
        return <PostAddIcon />;
      case "Legislative Assembly Constituency":
        return <PeopleIcon />;
      case "Parliament Constituency":
        return <GroupIcon />;
      case "District":
        return <MyLocationIcon />;
      case "Tehsil":
        return <LocationCityIcon />;
      case "Sector":
        return <ListAltIcon />;
      case "Sub Sector":
        return <ListAltIcon />;
      case "Business Entity Type":
        return <BusinessIcon />;
      case "Platform":
        return <IntegrationInstructionsIcon />;
      case "Grievance":
        return <SummarizeIcon />;
      case "Priority":
        return <LowPriorityIcon />;
      case "State":
        return <ApartmentIcon />;
      case "Block":
        return <WidgetsIcon />;
      case "Specialization":
        return <FolderSpecialIcon />;
      // case "Industry":
      //   return <FactoryIcon />;
      case "All Application":
        return <WidgetsIcon />;
      case "Old Application":
        return <ReceiptLongIcon />;
      case "Start Up Stage":
        return <ApprovalIcon />;
      case "Mentor":
        return <AccessibilityIcon />;
      case "Recognition Form":
        return <Face5Icon />;
      case "Add To Proceedings":
        return <ViewAgendaIcon />;
      case "Proceeding List":
        return <EventIcon />;
      case "Select Mentor":
        return <AccessibilityIcon />;
      case "Incubation Center":
        return <AccessibilityIcon />;
      case "Grievances":
        return <FlagCircleIcon />;
      case "All Recognition":
        return <Face6Icon />;
      case "Mentor Dashboard":
        return <DashboardIcon />;
      default:
        return null;
    }
  };