import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Breadcrumbs,
  Divider,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { IconButton, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  useIncubationDetailsQuery,
  useCreateProgressReportMutation,
  useGetDistrictQuery,
  useUploadDetailFileMutation,
  api,
  useUpdateProgressReportMutation,
  useChangePrStatusByMentorMutation,
  useChangePrStatusByIncubationMutation,
  useChangePrStatusByDicMutation,
  useChangePrStatusByHODMutation,
} from "../../services/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { ThemeContext } from "../../services/ThemeContext";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CustomizedProgressBars from "../CustomProgress/ProgressBar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import TrackPR from "./TrackPR";
import * as Yup from "yup";

const commonColor = {
  styles: {
    borderStyle: "solid",
    borderColor: "#000000",
    borderBottomWidth: "medium",
    opacity: 1,
  },
};
const ProgressPreview = () => {
  const { id } = useParams();
  const [prRoute] = api.endpoints.singleProgressReport.useLazyQuery();
  const location = useLocation();
  // const [loading, setLoading] = useState(false);
  const {
    data: incubationList,
    isLoading,
    isError,
  } = useIncubationDetailsQuery();
  const [createPR] = useCreateProgressReportMutation();
  const [updPR] = useUpdateProgressReportMutation();
  const [uploadFile] = useUploadDetailFileMutation();
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef([]);
  const fileInputRef4 = useRef(null);
  const { data: districtList } = useGetDistrictQuery(); // Get Incubation list
  const [sendToMentor] = useChangePrStatusByMentorMutation();
  const [sendToNodal] = useChangePrStatusByIncubationMutation();
  const [sendToDic] = useChangePrStatusByDicMutation();
  const [sendToHOD] = useChangePrStatusByHODMutation();
  const role = localStorage.getItem("role").toLowerCase();
  const { fontSize } = useContext(ThemeContext);
  const navigate = useNavigate();
  if (!Array.isArray(fileInputRef3.current)) {
    fileInputRef3.current = [];
  }
  const validationSchema = Yup.object().shape({
    // newStatus: yup.string().required("Status is required"),
    nodal_remarks: Yup.string()
      .required("Remarks are required")
      .max(100, "Remarks must be 100 characters or less"),
    mentor_remarks: Yup.string()
      .required("Remarks are required")
      .max(100, "Remarks must be 100 characters or less"),
    dic_remarks: Yup.string()
      .required("Remarks are required")
      .max(100, "Remarks must be 100 characters or less"),
    hod_remarks: Yup.string()
      .required("Remarks are required")
      .max(100, "Remarks must be 100 characters or less"),
  });
  const formik = useFormik({
    initialValues: {
      report_number: "",
      startup_name: "",
      founder_name: "",
      women_led_startup: "no",
      category: "",
      incubation_center: "",
      incubationStartDate: new Date(),
      mentor_name: "",
      address: "",
      district: "",
      mobile_number: "",
      email: "",
      state_startup_registration_number: "",
      incorporation_number: "",
      task_completed: "",
      prev_report_num: 0,
      summary: "",
      current_stage: "",
      turn_over: 0,
      turn_over_proof: "",
      investment_funding: "",
      events_attended: "",
      turn_over_name: "",
      upload_doc_name: "",
      investment_funding_name: "",
      fieldsCount: 1,
      custom_fields: [
        { event: "", date: new Date(), details: null, details_name: null },
      ],
      industryCount: 1,
      industry_custom_fields: [
        { name: "", email: "", office_address: "", contact: "" },
      ],
      upload_doc: "",
      comment_by_mentor: "",
      comment_by_nodal_officer: "",
      comment_by_gm: "",
      comment_by_directorate_of_industries: "",
      // <<==================Approval phase of the PR============================>>
      mentor_status: "",
      mentor_remarks: "",
      mentor_status_pr: "",

      nodal_status: "",
      nodal_remarks: "",

      dic_status: "",
      dic_remarks: "",

      hod_status: "",
      hod_remarks: "",
      loading: false,
      loading1: false,
      loading2: false,
      loading3: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
  });
  const [prevLoading, setPrevLoading] = useState(true);
  const handleFileUpload = (event, fieldName) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue(fieldName, file);
    if (fieldName === "turn_over_proof")
      formik.setFieldValue("turn_over_name", file.name);
    if (fieldName === "investment_funding")
      formik.setFieldValue("investment_funding_name", file.name);
    if (fieldName === "upload_doc")
      formik.setFieldValue("upload_doc_name", file.name);
  };
  async function generatePDF() {
    try {
      const { data } = await prRoute(id);

      if (data?.success) {
        const {
          name_founder,
          women_led_startup,
          category,
          incubation_centre,
          address,
          email,
          contact_number,
          district,
          startup_name,
          incorporation_number,
          name_of_mantor,
          task_complete,
          previous_report_number,
          summary,
          current_stage,
          turn_over,
          turn_over_proof,
          investment_proof,
          upload_document,
          event_attended,
          industry_tie_up,
          incubation_start_date,
          report_number,
          state_startup_registration_number,
        } = data?.data;

        const doc = new jsPDF();

        doc.setFontSize(14);
        doc.text("PR Details", 10, 10);
        doc.setFontSize(10);
        doc.text(`Report Number:  ${report_number || "N/A"}`, 10, 20);
        doc.text(`Name of the Founder:  ${name_founder || "N/A"}`, 10, 30);
        doc.text(`Category:  ${category || "N/A"}`, 10, 40);
        doc.text(`Startup Name:  ${address || "N/A"}`, 10, 50);
        doc.text(`Contact:  ${startup_name || "N/A"}`, 10, 60);
        doc.text(`AADHAAR:  ${name_of_mantor || "N/A"}`, 10, 70);
        doc.text(
          `Address of the Founder:  ${previous_report_number || "N/A"}`,
          10,
          80
        );
        doc.text(`DPIIT Recognised Certificate:  ${summary || "N/A"}`, 10, 90);
        doc.text(
          `Products Services Description:  ${current_stage || "N/A"}`,
          10,
          100
        );
        doc.text(`Women Led StartUp:  ${women_led_startup || "N/A"}`, 10, 110);
        doc.text(
          `Incorporation number:  ${incorporation_number || "N/A"}`,
          10,
          120
        );
        doc.text(`Initial investment:  ${turn_over || "N/A"}`, 10, 130);
        doc.text(
          `Incubation Start Date:  ${moment(
            incubation_start_date || "N/A"
          ).format("DD-MM-YYYY")}`,
          10,
          140
        );

        // Save the PDF
        doc.save("pr.pdf");
      }
    } catch (error) {
      console.log("🚀 ~ generatePDF ~ error:", error);
    }
  }
  React.useEffect(() => {
    const UpdatePR = async () => {
      if (id) {
        try {
          const { data, isLoading } = await prRoute(id);
          setPrevLoading(isLoading);
          if (data && data?.data) {
            const {
              name_founder,
              women_led_startup,
              category,
              incubation_centre,
              address,
              email,
              contact_number,
              district,
              startup_name,
              incorporation_number,
              name_of_mantor,
              task_complete,
              previous_report_number,
              summary,
              current_stage,
              turn_over,
              turn_over_proof,
              investment_proof,
              upload_document,
              event_attended,
              industry_tie_up,
              incubation_start_date,
              report_number,
              state_startup_registration_number,
              mentor_status,
              nodal_status,
              dic_status,
              directorate_status,
              mantor_comment,
              nodal_comment,
              dic_comment,
              directorate_comment,
            } = data?.data;

            await formik.setValues((prev) => ({
              ...prev,
              report_number,
              startup_name,
              founder_name: name_founder,
              mentor_name: name_of_mantor,
              women_led_startup,
              category,
              incubation_center: incubation_centre,
              address,
              district,
              mobile_number: contact_number,
              email,
              incorporation_number: incorporation_number,
              state_startup_registration_number:
                state_startup_registration_number,
              task_completed: task_complete,
              prev_report_num: previous_report_number,
              summary: summary,
              current_stage: current_stage,
              turn_over: turn_over,
              turn_over_proof: turn_over_proof,
              investment_funding: investment_proof,
              // industry_tie_ups: "",
              // documents: "",
              // turn_over_name: "",
              // upload_doc_name: "",
              // investment_funding_name: "",
              fieldsCount: JSON.parse(event_attended).length,
              custom_fields: JSON.parse(event_attended),
              industryCount: JSON.parse(industry_tie_up).length,
              industry_custom_fields: JSON.parse(industry_tie_up),
              upload_doc: upload_document,
              mentor_status: mentor_status,
              nodal_status: nodal_status,
              dic_status: dic_status,
              hod_status: directorate_status,
              mentor_remarks: mantor_comment,
              nodal_remarks: nodal_comment,
              dic_remarks: dic_comment,
              hod_remarks: directorate_comment,
            }));
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdatePR();
  }, [id, Option]);
  const handleChange = async (index, fieldName, value) => {
    const updatedCustomFields = [...formik.values.custom_fields];
    if (fieldName === "details") {
      updatedCustomFields[index]["details_name"] = value?.name;
      const formData = new FormData();
      formData.append("file_pdf", value);
      const res = await uploadFile(formData);
      updatedCustomFields[index]["details"] = res?.data?.data?.file_pdf;
    } else {
      updatedCustomFields[index][fieldName] = value;
    }
    formik.setFieldValue("custom_fields", updatedCustomFields);
  };

  const handleDecreaseFields = () => {
    if (formik.values.fieldsCount > 1) {
      formik.setFieldValue("fieldsCount", formik.values.fieldsCount - 1);
      formik.setFieldValue(
        "custom_fields",
        formik.values.custom_fields.slice(0, -1)
      ); // Remove the last set of fields
    }
  };
  const handleIncreaseFields = () => {
    formik.setFieldValue("fieldsCount", formik.values.fieldsCount + 1);
    formik.setFieldValue("custom_fields", [
      ...formik.values.custom_fields,
      { event: "", date: new Date(), details: null, details_name: null }, // Add an empty set of fields
    ]);
  };

  const handleIndustryChange = (index, field, value) => {
    const newIndustryFields = [...formik.values.industry_custom_fields];
    newIndustryFields[index][field] = value; // Update the specific field of the industry at the given index
    formik.setFieldValue("industry_custom_fields", newIndustryFields);
  };

  const handleDecreaseIndustryFields = () => {
    if (formik.values.fieldsCount > 1) {
      formik.setFieldValue("industryCount", formik.values.industryCount - 1);
      formik.setFieldValue(
        "industry_custom_fields",
        formik.values.industry_custom_fields.slice(0, -1)
      ); // Remove the last set of fields
    }
  };
  const handleIncreaseIndustryFields = () => {
    formik.setFieldValue("industryCount", formik.values.industryCount + 1);
    formik.setFieldValue("industry_custom_fields", [
      ...formik.values.industry_custom_fields,
      { name: "", email: "", office_address: "", contact: "" }, // Add an empty set of fields
    ]);
  };
  const handleMentorSection = async () => {
    formik.setFieldValue("loading", true);
    const remarks = formik.values.mentor_remarks;
        if (remarks.length > 100) {
          toast.error("Remarks must be 100 characters or less", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.setFieldValue("loading", false);
          return;
        }
    try {
      let data = {
        id,
        payload: {
          mentor_status: formik.values.mentor_status,
          mantor_comment: formik.values.mentor_remarks,
        },
      };
      const response = await sendToMentor(data);
      if (response?.data?.success) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // formik.setFieldValue("loading",false);
        setTimeout(() => {
          navigate("/application-status");
        }, 1000);
      } else if (response?.error) {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // formik.setFieldValue("loading",false);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setTimeout(() => {
        formik.setFieldValue("loading", false);
      }, 2000);
    }
  };
  const handleNodalSection = async () => {
    formik.setFieldValue("loading1", true);
    const remarks = formik.values.nodal_remarks;
    if (remarks.length > 100) {
      toast.error("Remarks must be 100 characters or less", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      formik.setFieldValue("loading1", false);
      return;
    }
    try {

        let data = {
          id,
          payload: {
            nodal_status: formik.values.nodal_status,
            nodal_comment: formik.values.nodal_remarks,
          },
        };
        const response = await sendToNodal(data);
        if (response?.data?.success) {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // formik.setFieldValue("loading",false);
          setTimeout(() => {
            navigate("/application-status");
          }, 1000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // formik.setFieldValue("loading1",false);
        }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setTimeout(() => {
        formik.setFieldValue("loading1", false);
      }, 2000);
    }
  };
  const handleDicSection = async () => {
    formik.setFieldValue("loading2", true);
    const remarks = formik.values.dic_remarks;
    if (remarks.length > 100) {
      toast.error("Remarks must be 100 characters or less", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      formik.setFieldValue("loading2", false);
      return;
    }
    try {
      let data = {
        id,
        payload: {
          dic_status: formik.values.dic_status,
          dic_comment: formik.values.dic_remarks,
        },
      };
      const response = await sendToDic(data);
      if (response?.data?.success) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // formik.setFieldValue("loading",false);
        setTimeout(() => {
          navigate("/application-status");
        }, 1000);
      } else if (response?.error) {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        formik.setFieldValue("loading2", false);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setTimeout(() => {
        formik.setFieldValue("loading2", false);
      }, 2000);
    }
  };
  const handleHODSection = async () => {
    formik.setFieldValue("loading3", true);
    const remarks = formik.values.hod_remarks;
    if (remarks.length > 100) {
      toast.error("Remarks must be 100 characters or less", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      formik.setFieldValue("loading3", false);
      return;
    }
    try {
      let data = {
        id,
        payload: {
          directorate_status: formik.values.hod_status,
          directorate_comment: formik.values.hod_remarks,
        },
      };
      const response = await sendToHOD(data);
      if (response?.data?.success) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // formik.setFieldValue("loading",false);
        setTimeout(() => {
          navigate("/application-status");
        }, 1000);
      } else if (response?.error) {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        formik.setFieldValue("loading3", false);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setTimeout(() => {
        formik.setFieldValue("loading3", false);
      }, 2000);
    }
  };



  return (
    <Box>
      <ToastContainer />
      <Box marginTop={"6em"} padding={4}>
        <Paper className="paper-card">
          <Grid container>
            <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
              <Typography
                variant="h6"
                align="start"
                sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
              >
                Progress Report Tracking Status
              </Typography>

              <TrackPR id={id} />
            </Grid>
            {prevLoading ? (
              <CircularProgress
                variant="indeterminate"
                color="success"
                sx={{ marginTop: 30 }}
              />
            ) : null}
          </Grid>
          <Box textAlign="right">
            <Tooltip title="Download PDF" arrow>
              <IconButton onClick={generatePDF} color="primary">
                <DownloadIcon sx={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          </Box>
          {/* TrackPR */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "20%",
              flexDirection: "column",
              gap: "10px",
              marginTop: 1,
              marginBottom: "2rem",
            }}
          >
            <Grid item md={6} lg={6} sm={6}>
              <Typography variant="h5" align="center" fontWeight="600">
                Progress Report Preview
              </Typography>
            </Grid>
            <Grid item md={6} lg={6} sm={6}>
              <CustomizedProgressBars val={50} />
            </Grid>
          </Grid>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>
              {/* incubation Type */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="report_number"
                  label="Report Number"
                  name="report_number"
                  placeholder="Report Number"
                  autoComplete="report_number"
                  value={formik.values.report_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.report_number &&
                    Boolean(formik.errors.report_number)
                  }
                  helperText={
                    formik.touched.report_number && formik.errors.report_number
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  aria-readonly={true}
                  id="startup_name"
                  label="Startup Name"
                  name="startup_name"
                  placeholder="Startup Name"
                  autoComplete="startup_name"
                  value={formik.values.startup_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.startup_name &&
                    Boolean(formik.errors.startup_name)
                  }
                  helperText={
                    formik.touched.startup_name && formik.errors.startup_name
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="founder_name"
                  label="Founder Name"
                  name="founder_name"
                  aria-readonly
                  placeholder="Founder Name"
                  autoComplete="founder_name"
                  value={formik.values.founder_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.founder_name &&
                    Boolean(formik.errors.founder_name)
                  }
                  helperText={
                    formik.touched.founder_name && formik.errors.founder_name
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>

              {/* Date of birth */}

              {/* Gender */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth>
                  <FormLabel id="women-led-startup-label">
                    Women Led Start Up
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="women-led-startup-label"
                    name="women_led_startup" // Ensure this matches formik initialValues key
                    value={formik.values.women_led_startup}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      disabled
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="Category">Select Category</InputLabel>
                  <Select
                    labelId="Select Category"
                    readOnly
                    fullWidth
                    id="Category"
                    label="Category"
                    name="category" // Ensure the name matches the formik initialValues key
                    autoComplete="off"
                    placeholder="Category"
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  >
                    {["ST", "SC", "OBC", "General"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="incubation_center">
                    Incubation Centre
                  </InputLabel>
                  <Select
                    readOnly
                    labelId="Select Incubation Centre"
                    required
                    fullWidth
                    id="incubation_center"
                    label="Incubation Center"
                    name="incubation_center"
                    autoComplete="off"
                    placeholder="incubation_center"
                    defaultValue={""}
                    onChange={formik.handleChange}
                    value={formik.values.incubation_center}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.incubation_center &&
                      Boolean(formik.errors.incubation_center)
                    }
                    helperText={
                      formik.touched.incubation_center &&
                      formik.errors.incubation_center
                    }
                    //   onChange={(e) => HandleFilterPostChange(e)}
                  >
                    {incubationList?.data?.map((item, index) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.host_institute_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <DatePicker
                  disableFuture
                  readOnly
                  sx={{
                    width: "100%",
                    marginTop: "1em",
                    marginBottom: "0.5em",
                  }}
                  value={dayjs(
                    moment(formik.values.incubationStartDate).format(
                      "YYYY-MM-DD"
                    )
                  )}
                  selected={formik.values.incubationStartDate}
                  name="incubationStartDate"
                  label="Date of birth"
                  margin="normal"
                  onChange={(value) =>
                    formik.setFieldValue("incubationStartDate", value["$d"])
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="Mentor Name"
                  label="Mentor Name"
                  name="mentor_name"
                  aria-readonly
                  placeholder="Mentor Name"
                  autoComplete="mentor_name"
                  value={formik.values.mentor_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mentor_name &&
                    Boolean(formik.errors.mentor_name)
                  }
                  helperText={
                    formik.touched.mentor_name && formik.errors.mentor_name
                  }
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  aria-readonly
                  placeholder="Address"
                  autoComplete="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="district">Select District</InputLabel>
                  <Select
                    readOnly
                    labelId="Select District"
                    required
                    fullWidth
                    id="district"
                    label="District"
                    name="district" // Ensure the name matches the formik initialValues key
                    autoComplete="off"
                    placeholder="district"
                    onChange={formik.handleChange}
                    value={formik.values.district}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched.district && formik.errors.district
                    }
                  >
                    {districtList?.data?.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  margin="normal"
                  required
                  fullWidth
                  id="number"
                  label="Mobile Number"
                  name="mobile_number"
                  autoComplete="mobile_number"
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.mobile_number &&
                    Boolean(formik.errors.mobile_number)
                  }
                  helperText={
                    formik.touched.mobile_number && formik.errors.mobile_number
                  }
                />
              </Grid>

              {/* Email */}
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  autoComplete="email"
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="registration_number"
                  label="State StartUp Registration Number"
                  name="state_startup_registration_number"
                  value={formik.values.state_startup_registration_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.state_startup_registration_number &&
                    Boolean(formik.errors.state_startup_registration_number)
                  }
                  helperText={
                    formik.touched.state_startup_registration_number &&
                    formik.errors.state_startup_registration_number
                  }
                  autoComplete="state_startup_registration_number"
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="incorporation_number"
                  label="Incorporation Number"
                  name="incorporation_number"
                  value={formik.values.incorporation_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incorporation_number &&
                    Boolean(formik.errors.incorporation_number)
                  }
                  helperText={
                    formik.touched.incorporation_number &&
                    formik.errors.incorporation_number
                  }
                  autoComplete="incorporation_number"
                  // onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="Task_Completed">
                    Select Task Completed
                  </InputLabel>
                  <Select
                    readOnly
                    labelId="Select Task Completed"
                    required
                    fullWidth
                    id="Task_Completed"
                    label="Task Completed"
                    name="task_completed"
                    autoComplete="off"
                    placeholder="Task Completed"
                    defaultValue={""}
                    value={formik.values.task_completed}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.task_completed &&
                      Boolean(formik.errors.task_completed)
                    }
                    helperText={
                      formik.touched.task_completed &&
                      formik.errors.task_completed
                    }
                  >
                    {["Task1", "Task2", "Task3"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    margin="normal"
                    type="textarea"
                    rows={2}
                    multiline
                    required
                    fullWidth
                    id="summary"
                    label="Summary"
                    name="summary"
                    value={formik.values.summary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.summary && Boolean(formik.errors.summary)
                    }
                    helperText={formik.touched.summary && formik.errors.summary}
                    autoComplete="summary"
                  />
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="current_stage">Current Stage</InputLabel>
                  <Select
                    readOnly
                    labelId="Select Incubation Centre"
                    required
                    fullWidth
                    id="current_stage"
                    label="Incubation Center"
                    name="current_stage"
                    autoComplete="off"
                    placeholder="current_stage"
                    defaultValue={""}
                    value={formik.values.current_stage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.current_stage &&
                      Boolean(formik.errors.current_stage)
                    }
                    helperText={
                      formik.touched.current_stage &&
                      formik.errors.current_stage
                    }
                  >
                    {[
                      "Ideation",
                      "Prototype development",
                      "Under Commercialisation",
                      "Commercialisation",
                    ]?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  margin="normal"
                  type="number"
                  rows={2}
                  required
                  fullWidth
                  id="turn_over"
                  label="Turn Over"
                  name="turn_over"
                  value={formik.values.turn_over}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.turn_over && Boolean(formik.errors.turn_over)
                  }
                  helperText={
                    formik.touched.turn_over && formik.errors.turn_over
                  }
                  autoComplete="off"
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl
                  className="file-upload-style"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  }}
                >
                  <CloudUploadIcon
                    className="icon-center"
                    style={{ cursor: "pointer", color: "#00bfff" }}
                    onClick={() => fileInputRef1.current.click()}
                  />
                  {id && formik?.values?.turn_over_proof ? (
                    <Link
                      to={formik?.values?.turn_over_proof}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon />
                    </Link>
                  ) : (
                    <></>
                  )}
                  <Typography color="black">
                    {formik.values.turn_over_name}
                  </Typography>
                  <FormLabel>Turn Over Proof</FormLabel>
                  <input
                    type="file"
                    accept=".pdf"
                    disabled
                    style={{ display: "none" }}
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileUpload(event, "turn_over_proof")
                    }
                  />
                  {formik.errors.turn_over_proof &&
                    formik.touched.turn_over_proof && (
                      <Typography color="error">
                        {formik.errors.turn_over_proof}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl
                  className="file-upload-style"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  }}
                >
                  <CloudUploadIcon
                    className="icon-center"
                    style={{ cursor: "pointer", color: "#00bfff" }}
                    onClick={() => fileInputRef2.current.click()}
                  />
                  {id && formik?.values?.investment_funding ? (
                    <Link
                      to={formik?.values?.investment_funding}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VisibilityIcon />
                    </Link>
                  ) : (
                    <></>
                  )}
                  <Typography color="black" fontSize={14}>
                    {formik.values.investment_funding_name}
                  </Typography>
                  <FormLabel>Investment/Funding</FormLabel>
                  <input
                    type="file"
                    accept=".pdf"
                    disabled
                    style={{ display: "none" }}
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileUpload(event, "investment_funding")
                    }
                  />
                  {formik.errors.investment_funding &&
                    formik.touched.investment_funding && (
                      <Typography color="error">
                        {formik.errors.investment_funding}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // mt={2}
                // display={"flex"}
                // sx={{ gap: 2 }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ fontWeight: 400, fontSize: "18px" }}
                >
                  Events Attended:
                </Typography>
              </Grid>

              {[...Array(formik.values.fieldsCount)].map((_, index) => (
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  // mt={2}
                  display={"flex"}
                  sx={{ gap: 2 }}
                >
                  <React.Fragment key={index}>
                    <Grid item xl={4} lg={4} md={4} sm={8} xs={8}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        fullWidth
                        // required
                        id={`field-${index}`}
                        label="Event"
                        name={`field-${index}`}
                        placeholder="Event"
                        autoComplete={`Event-${index}`}
                        value={formik.values?.custom_fields[index]?.event}
                        onChange={(e) =>
                          handleChange(index, "event", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.custom_fields && // Check if custom_fields array has been touched
                          formik.touched.custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.custom_fields && // Check if there are errors in custom_fields array
                          formik.errors.custom_fields[index]?.event // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.custom_fields && // Check if custom_fields array has been touched
                          formik.touched.custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.custom_fields && // Check if there are errors in custom_fields array
                          formik.errors.custom_fields[index]?.event // Show error message for this specific field
                        }
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={8} xs={8}>
                      <DatePicker
                        readOnly
                        disableFuture
                        sx={{
                          width: "100%",
                          marginTop: "1em",
                          marginBottom: "0.5em",
                        }}
                        value={dayjs(
                          moment(
                            formik.values?.custom_fields[index]?.date
                          ).format("YYYY-MM-DD")
                        )}
                        selected={formik.values?.custom_fields[index]?.date}
                        id={`date-${index}`}
                        label="Date"
                        name={`date-${index}`}
                        autoComplete={`date-${index}`}
                        margin="normal"
                        onChange={(e) => handleChange(index, "date", e["$d"])}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6} mt={2}>
                      <FormControl
                        className="file-upload-style"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                        }}
                      >
                        <Grid display={"flex"} sx={{ gap: 1 }}>
                          <CloudUploadIcon
                            style={{ cursor: "pointer", color: "#00bfff" }}
                            onClick={() => fileInputRef3.current[index].click()}
                          />
                          {id && formik?.values?.custom_fields.length > 0 ? (
                            <Link
                              to={formik?.values?.custom_fields[index]?.details}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <VisibilityIcon />
                            </Link>
                          ) : (
                            <></>
                          )}
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                            }}
                            fontSize={14}
                          >
                            {formik.values.custom_fields[index]?.details_name}
                          </Typography>
                          <input
                            type="file"
                            accept=".pdf"
                            disabled
                            style={{ display: "none" }}
                            ref={(el) => (fileInputRef3.current[index] = el)}
                            onChange={(event) =>
                              handleChange(
                                index,
                                "details",
                                event.target.files[0]
                              )
                            }
                          />
                          {formik.errors.custom_fields &&
                            formik.touched.custom_fields &&
                            formik.errors.custom_fields[index]?.details && (
                              <Typography color="error">
                                {formik.errors.custom_fields[index].details}
                              </Typography>
                            )}
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Details
                          </FormLabel>
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
                      <IconButton
                        color="primary"
                        onClick={() => handleIncreaseFields(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                      {formik.values.custom_fields.length > 1 && (
                        <IconButton
                          color="secondary"
                          onClick={() => handleDecreaseFields(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                </Grid>
              ))}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // mt={2}
                // display={"flex"}
                // sx={{ gap: 2 }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  sx={{ fontWeight: 400, fontSize: "18px" }}
                >
                  Industry Tie ups:
                </Typography>
              </Grid>

              {[...Array(formik.values.industryCount)].map((_, index) => (
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  // mt={2}
                  display={"flex"}
                  sx={{ gap: 2 }}
                >
                  <React.Fragment key={index}>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        fullWidth
                        // required
                        id={`Name-${index}`}
                        label="Name"
                        name={`name-${index}`}
                        placeholder="Name"
                        autoComplete={`Name-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]?.name
                        }
                        onChange={(e) =>
                          handleIndustryChange(index, "name", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.name // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.name // Show error message for this specific field
                        }
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        fullWidth
                        // required
                        id={`Email-${index}`}
                        label="Email"
                        name={`Email-${index}`}
                        placeholder="Email"
                        autoComplete={`Email-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]?.email
                        }
                        onChange={(e) =>
                          handleIndustryChange(index, "email", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.email // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.email // Show error message for this specific field
                        }
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        fullWidth
                        // required
                        id={`Contact-${index}`}
                        label="Contact"
                        name={`Contact-${index}`}
                        placeholder="Contact"
                        autoComplete={`Contact-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]?.contact
                        }
                        onChange={(e) =>
                          handleIndustryChange(index, "contact", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.contact // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]?.contact // Show error message for this specific field
                        }
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        fullWidth
                        // required
                        id={`OfficeAddress-${index}`}
                        label="Office Address"
                        name={`OfficeAddress-${index}`}
                        placeholder="Office Address"
                        autoComplete={`OfficeAddress-${index}`}
                        value={
                          formik.values?.industry_custom_fields[index]
                            ?.office_address
                        }
                        onChange={(e) =>
                          handleIndustryChange(
                            index,
                            "office_address",
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]
                            ?.office_address // Check if there's an error for this specific field
                        }
                        helperText={
                          formik.touched.industry_custom_fields && // Check if industry_custom_fields array has been touched
                          formik.touched.industry_custom_fields[index] && // Check if this specific field has been touched
                          formik.errors.industry_custom_fields && // Check if there are errors in industry_custom_fields array
                          formik.errors.industry_custom_fields[index]
                            ?.office_address // Show error message for this specific field
                        }
                      />
                    </Grid>

                    <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
                      <IconButton
                        color="primary"
                        onClick={() => handleIncreaseIndustryFields(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                      {formik.values.industry_custom_fields.length > 1 && (
                        <IconButton
                          color="secondary"
                          onClick={() => handleDecreaseIndustryFields(index)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                </Grid>
              ))}

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={2}>
                <FormControl
                  className="file-upload-style"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.default,
                  }}
                >
                  <Grid display={"flex"} sx={{ gap: 2 }}>
                    <Grid>
                      <CloudUploadIcon
                        style={{ cursor: "pointer", color: "#00bfff" }}
                        onClick={() => fileInputRef4.current.click()}
                      />
                      {id && formik?.values?.upload_doc ? (
                        <Link
                          to={formik?.values?.upload_doc}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VisibilityIcon />
                        </Link>
                      ) : (
                        <></>
                      )}
                      <Typography color="black" fontSize={14}>
                        {formik.values.upload_doc_name}
                      </Typography>
                      <input
                        type="file"
                        disabled
                        accept=".pdf"
                        style={{ display: "none" }}
                        ref={fileInputRef4}
                        onChange={(event) =>
                          handleFileUpload(event, "upload_doc")
                        }
                      />
                      {formik.errors.upload_doc &&
                        formik.touched.upload_doc && (
                          <Typography color="error">
                            {formik.errors.upload_doc}
                          </Typography>
                        )}
                    </Grid>
                    <Grid>
                      <FormLabel>Upload Documents</FormLabel>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Grid sx={{ textAlign: "center", marginTop: 2 }}>
            {formik.values.loading ? (
              <CircularProgress color="primary" />
            ) : null}
          </Grid>

          {role === "mentor" || role === "superadmin" ? (
            <>
              <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
                <Divider orientation={"horizontal"} sx={commonColor?.styles} />

                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
                >
                  Mentor Section
                </Typography>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                mt={2}
              >
                <Grid item md={6} lg={6} xl={6} xs={12}>
                  {/* <Typography
    variant="h6"
    align="start"
    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
  >
    Status:
  </Typography> */}
                  <FormControl
                    fullWidth
                    sx={{ marginTop: 2 }}
                    variant="outlined"
                  >
                    <InputLabel
                      id="mentor_status"
                      shrink={formik.values.mentor_status ? true : false}
                    >
                      Status
                    </InputLabel>
                    <Select
                      labelId="mentor_status"
                      required
                      fullWidth
                      id="mentor_status"
                      label="Status"
                      name="mentor_status"
                      autoComplete="off"
                      placeholder="Status"
                      value={formik.values.mentor_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mentor_status &&
                        Boolean(formik.errors.mentor_status)
                      }
                      helperText={
                        formik.touched.mentor_status &&
                        formik.errors.mentor_status
                      }
                      inputProps={{
                        "aria-label": "mentor_status",
                      }}
                    >
                      <MenuItem value="">--Select Status--</MenuItem>
                      <MenuItem value="reverted">Revert</MenuItem>
                      <MenuItem value="accepted">Forward</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                  {/* <Typography
                    variant="h6"
                    align="start"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Remarks:
                  </Typography> */}
                  <TextField
                   type="textarea"
                   rows={3}
                   multiline
                    label="Remarks"
                    required
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="mentor_remarks"
                    id="mentor_remarks"
                    value={formik.values.mentor_remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mentor_remarks &&
                      Boolean(formik.errors.mentor_remarks)
                    }
                    helperText={
                      formik.touched.mentor_remarks &&
                      formik.errors.mentor_remarks
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="button-right"
              >
                {/* <Button
              type="button"
              variant="contained"
              onClick={()=>{navigate('/application-status')}}
              // InputProps={{
                  readOnly: true,
                }}={active === 0}
            >
              Previous
            </Button> */}
                <Box ml={2} display="inline-block">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      handleMentorSection();
                    }}
                    disabled={
                      formik.values.nodal_status !== null ? true : false
                    }
                    endIcon={<SendIcon />}
                    loading={formik.values.loading}
                    loadingPosition="end"
                    type="submit"
                    size="small"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </>
          ) : null}
          <Grid sx={{ textAlign: "center", marginTop: 2 }}>
            {formik.values.loading1 ? (
              <CircularProgress color="primary" />
            ) : null}
          </Grid>
          {((role === "incubation" || role === "superadmin") &&  formik.values.mentor_status==="accepted")? (
            <>
              <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
                <Divider orientation={"horizontal"} sx={commonColor?.styles} />

                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
                >
                  Nodal Section
                </Typography>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                mt={2}
              >
                <Grid item md={6} lg={6} xl={6} xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="start"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Status:
                  </Typography> */}
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="nodal_status">Status</InputLabel>
                    <Select
                      labelId="Select Status"
                      required
                      fullWidth
                      id="nodal_status"
                      label="Status"
                      name="nodal_status"
                      autoComplete="off"
                      placeholder="Status"
                      value={formik.values.nodal_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.nodal_status &&
                        Boolean(formik.errors.nodal_status)
                      }
                      helperText={
                        formik.touched.nodal_status &&
                        formik.errors.nodal_status
                      }
                    >
                      <MenuItem value="">--Select Status--</MenuItem>
                      <MenuItem value="reverted">Revert</MenuItem>
                      <MenuItem value="accepted">Forward</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                  {/* <Typography
                    variant="h6"
                    align="start"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Remarks:
                  </Typography> */}
                  <TextField
                    type="textarea"
                    rows={3}
                    multiline
                    label="Remarks"
                    required
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="nodal_remarks"
                    id="nodal_remarks"
                    value={formik.values.nodal_remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nodal_remarks &&
                      Boolean(formik.errors.nodal_remarks)
                    }
                    helperText={
                      formik.touched.nodal_remarks &&
                      formik.errors.nodal_remarks
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="button-right"
              >
                {/* <Button
              type="button"
              variant="contained"
              onClick={()=>{navigate('/application-status')}}
              // InputProps={{
                  readOnly: true,
                }}={active === 0}
            >
              Previous
            </Button> */}
                <Box ml={2} display="inline-block">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      handleNodalSection();
                    }}
                    disabled={formik.values.dic_status !== null ? true : false}
                    // disabled={formik.values.nodal_status!==null}
                    endIcon={<SendIcon />}
                    loading={formik.values.loading1}
                    loadingPosition="end"
                    type="submit"
                    size="small"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </>
          ) : null}
          <Grid sx={{ textAlign: "center", marginTop: 2 }}>
            {formik.values.loading2 ? (
              <CircularProgress color="primary" />
            ) : null}
          </Grid>
          {((role === "dic" || role === "superadmin") && (formik.values.nodal_status==="accepted" &&formik.values.mentor_status==="accepted"))? (
            <>
              <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
                <Divider orientation={"horizontal"} sx={commonColor?.styles} />

                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
                >
                  GM/DIC Section
                </Typography>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                mt={2}
              >
                <Grid item md={6} lg={6} xl={6} xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Status:
                  </Typography> */}
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="dic_status">Status</InputLabel>
                    <Select
                      labelId="Select Status"
                      required
                      fullWidth
                      id="dic_status"
                      label="Status"
                      name="dic_status"
                      autoComplete="off"
                      placeholder="Status"
                      value={formik.values.dic_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.dic_status &&
                        Boolean(formik.errors.dic_status)
                      }
                      helperText={
                        formik.touched.dic_status && formik.errors.dic_status
                      }
                    >
                      <MenuItem value="">--Select Status--</MenuItem>
                      <MenuItem value="reverted">Revert</MenuItem>
                      <MenuItem value="accepted">Forward</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                  {/* <Typography
                    variant="h6"
                    align="start"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Remarks:
                  </Typography> */}
                  <TextField
                   type="textarea"
                   rows={3}
                   multiline
                    label="Remarks"
                    required
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="dic_remarks"
                    id="dic_remarks"
                    value={formik.values.dic_remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dic_remarks &&
                      Boolean(formik.errors.dic_remarks)
                    }
                    helperText={
                      formik.touched.dic_remarks && formik.errors.dic_remarks
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="button-right"
              >
                {/* <Button
              type="button"
              variant="contained"
              onClick={()=>{navigate('/application-status')}}
              // InputProps={{
                  readOnly: true,
                }}={active === 0}
            >
              Previous
            </Button> */}
                <Box ml={2} display="inline-block">
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      handleDicSection();
                    }}
                    disabled={formik.values.hod_status !== null ? true : false}
                    endIcon={<SendIcon />}
                    loading={formik.values.loading2}
                    loadingPosition="end"
                    type="submit"
                    size="small"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </>
          ) : null}
          <Grid sx={{ textAlign: "center", marginTop: 2 }}>
            {formik.values.loading3 ? (
              <CircularProgress color="primary" />
            ) : null}
          </Grid>
          {((role === "hod" || role === "superadmin" || role === "admin") && (formik.values.dic_status==="accepted"&&formik.values.mentor_status==="accepted"&& formik.values.nodal_status==="accepted")) ? (
            <>
              <Grid item md={6} lg={6} xl={6} xs={12} sx={{ marginTop: 2 }}>
                <Divider orientation={"horizontal"} sx={commonColor?.styles} />

                <Typography
                  variant="h6"
                  align="start"
                  sx={{ fontWeight: 600, color: "#2074af", marginTop: 2 }}
                >
                  HOD/Directorate Section
                </Typography>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                mt={2}
              >
                <Grid item md={6} lg={6} xl={6} xs={12}>
                  {/* <Typography
                    variant="h6"
                    align="start"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Status:
                  </Typography> */}
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="hod_status">Status</InputLabel>
                    <Select
                      labelId="Select Status"
                      required
                      fullWidth
                      id="hod_status"
                      label="Status"
                      name="hod_status"
                      autoComplete="off"
                      placeholder="Status"
                      value={formik.values.hod_status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.hod_status &&
                        Boolean(formik.errors.hod_status)
                      }
                      helperText={
                        formik.touched.hod_status && formik.errors.hod_status
                      }
                    >
                      <MenuItem value="">--Select Status--</MenuItem>
                      <MenuItem value="rejected">Reject</MenuItem>
                      <MenuItem value="approved">Approved</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
                  {/* <Typography
                    variant="h6"
                    align="start"
                    sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
                  >
                    Remarks:
                  </Typography> */}
                  <TextField
                   type="textarea"
                   rows={3}
                   multiline
                    label="Remarks"
                    required
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="hod_remarks"
                    id="hod_remarks"
                    value={formik.values.hod_remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.hod_remarks &&
                      Boolean(formik.errors.hod_remarks)
                    }
                    helperText={
                      formik.touched.hod_remarks && formik.errors.hod_remarks
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="button-right"
              >
                <Box ml={2} display="inline-block">
                  <LoadingButton
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      handleHODSection();
                    }}
                    // {active === steps.length - 1}
                    endIcon={<SendIcon />}
                    loading={formik.values.loading3}
                    loadingPosition="end"
                    type="submit"
                    size="small"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </>
          ) : null}
        </Paper>
      </Box>
    </Box>
  );
};

export default ProgressPreview;
