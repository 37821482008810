import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Contact from "./Screens/Contact";
import About from "./Screens/About";
import Register from "./Screens/Register";
import { useDispatch } from "react-redux";
import { setSideBarContent, setSsoData } from "./Redux/GlobalSlice";
import { LanguageProvider } from "./Components/Language/CustomContext";
import { ErrorBoundary } from "react-error-boundary";
import FallBack from "./ErrorElement/FallBack";
import Homev2 from "./Screens/Homev2/index";
import renderSidebar from "./Components/Render/RenderSidebar";
import { ProtectedRoute } from "./ProtectedRoute";
import PageNotFound from "./ErrorElement/PageNotFound";
import Dashboard from "./Screens/Dashboard/index";
import RoutesIndex from "./Screens/Routes";
import RolesIndex from "./Screens/Roles";
import PermissionIndex from "./Screens/Permissions";
import UpsertPermissions from "./Screens/UpsertPermissions";
import { TextEditor } from "./Screens/Editor/TextEditor";
import IncubationUser from "./Screens/IncubationUser";
import IncubationTable from "./Screens/IncubationUser/Table";
import { api, useSsoLoginMutation } from "./services/api";
import CategoryIndex from "./Screens/Category";
import PostTypeIndex from "./Screens/PostType";
import CustomFieldIndex from "./Screens/CustomField";
import CustomField from "./Screens/UpsertCustomField";
import CMSIndex from "./Screens/CMS";
import { PermissionCheck } from "./Helpers/PermissionChecker";
import PermissionFallBack from "./ErrorElement/PermissionFallBack";
import LoaderFallBack from "./Loader";
import { Box, useTheme } from "@mui/material";
import ParliamentIndex from "./Screens/Parliament";
import TehsilIndex from "./Screens/Tehsil";
import SectorIndex from "./Screens/Sector";
import SubSectorIndex from "./Screens/Sub Sector";
import BusinessEntityIndex from "./Screens/BusinessEntityType";
import PlatformIndex from "./Screens/Platform";
import ProgressReportIndex from "./Screens/ProgressReport";
import UpsertProgressReport from "./Screens/UpsertProgressReport";
import LegislativeAssemblyIndex from "./Screens/LegislativeAssembly/index";
import DistrictIndex from "./Screens/District/index";
import PriorityIndex from "./Screens/Priority";
import LoginTabs from "./Screens/Login/LoginTabs";
import BlockIndex from "./Screens/Block/Index";
import StateIndex from "./Screens/State";
import Applicantv2 from "./Screens/Applicant/indexv2";
import SpecializationIndex from "./Screens/Specialization";
import ApplicationIndex from "./Screens/ApplicantionStatus";
import MentorRegister from "./Screens/MentorRegistration";
import StartUpStageIndex from "./Screens/StartUpStage";
import MentorRegisterIndex from "./Screens/MentorRegistration/MentorRegisterTable";
import RecognitionForm from "./Screens/RecognitionForm/indexv2";
import IncubationCentre from "./Screens/IncubationCentre";
import ApplicantPreview from "./Screens/Applicant/ApplicantPreview";
import GrievanceForm from "./Screens/GrievanceForm";
import AgendaIndex from "./Screens/Agenda";
import AgendaList from "./Screens/AgendaList";
import Proceedings from "./Screens/Proceedings";
import MentorCard from "./Components/SelectMentor";
import { LoginWithOTP } from "./Screens/Login/LoginOTP";
import Root from "./Components/Root";
import ProgressPreview from "./Screens/UpsertProgressReport/progressReportPreview";
import IncubationPreview from "./Screens/IncubationCentre/IncubationPreview";
import IncubationListIndex from "./Screens/IncubationList";
import GrievanceIndex from "./Screens/Grievances";
import UpsertGrievance from "./Screens/UpsertGrievance";
import Chat from "./GrievanceChat";
import RecognitionIndex from "./Screens/Recognition";
import Profile from "./Components/Profile";
import ThemeContextProvider from "./services/ThemeContext";
import RecognitionPreview from "./Screens/RecognitionForm/RecognitionPreview";
import { CheckTokenValidity } from "./Helpers/CheckTokenValidity";
import MentorPreview from "./Screens/MentorRegistration/MentorPreview";
import MentorDashboard from "./Screens/DashboardMentor";
import GrievanceDashboard from "./Screens/GrievancesDashboard";
import MentorInfo from "./Components/SelectMentor/Mentor";
import { ScreenReader } from "./Components/Topbar/ScreenReader";
import { ToastContainer, toast } from "react-toastify";
import SingleLog from "./Screens/Applicant/SingleLog";
import LogsList from "./Screens/Applicant/LogsList";
import PRSingleLog from "./Screens/UpsertProgressReport/PRSingleLog";
import RecoLog from "./Screens/RecognitionForm/RecoLog";
import MentorLog from "./Screens/MentorRegistration/Mentorlog";
import OldApplicationIndex from "./Screens/OldApplication";

const App = () => {
  const role =
    localStorage.getItem("role") !== null
      ? localStorage.getItem("role").toLowerCase()
      : "";
  const dispatch = useDispatch();
  const [getSsoData] = useSsoLoginMutation();
  const [routeList] = api.endpoints.menuDetails.useLazyQuery();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const [per, setPer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const permissionData = Object.keys(per).length > 0;
  const mainContentRef = useRef(null);
  
  
  //updated
  
  const data = async () => {
    if (token) {
      navigate("/dashboard", { replace: true });
      const { data: ssoData } = await getSsoData({ token: token });
      if (ssoData?.success) {
        localStorage.setItem("token", ssoData?.token);
        localStorage.setItem("name", ssoData?.data?.name);
        dispatch(setSsoData(ssoData?.data?.name));
        navigate("/dashboard", { replace: true });
       
      
      } else {
        localStorage.removeItem("name");
        navigate("/sign-in", { replace: true });
      }
    }
  };
  useEffect(() => {
    data();
  }, []);
  
  useEffect(() => {
    setTimeout(()=>{
      setIsLoading(true);
    },1000)
    const fetchData = async () => {
      try {
        const token=localStorage.getItem("token")
        const value= CheckTokenValidity(token,navigate)
        if(value){
            const res = await routeList(token);
            if(res?.data?.success){
              PermissionCheck({ content: res?.data?.total.data, setPer });
              dispatch(setSideBarContent(res?.data?.total.data));
            }
            else if(res?.error){
              toast.error(res?.error?.data?.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              localStorage.clear()
            }
            
            // navigate('/dashboard')
        }
        else{
          localStorage.removeItem("token")
        }       
      } catch (error) {
        console.error("Error fetching route list:", error);
        // Handle error as needed
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [localStorage.getItem("token"), permissionData]);
 
  const {
    category,
    cms,
    customfield,
    dashboard,
    permission,
    roles,
    routes,
    incubation_user,
    post_type,
    business_entity,
    legislative,
    district,
    tehsil,
    parliament,
    priority,
    platform,
    sector,
    sub_sector,
    progress_report,
    state,
    block,
    applicant,
    specialization,
    industry,
    application_status,
    old_application,
    startup_stage,
    mentor_register,
    recognition_form,
    grievance_form,
    agenda,
    agenda_list,
    select_mentor,
    incubation_center_preview,
    incubation_center,
    grievances,
    recognition,
    profile,
    mentor_dashboard,
    logs
  } = per;

  return (
    <Box className="theme-color-bg" >
      <ToastContainer />
      <ErrorBoundary fallback={<FallBack />}>
        <ThemeContextProvider>
          <LanguageProvider>
            <Routes>
              <Route path="/" element={<Root {...{ mainContentRef }} />}>
                <Route path="/" element={<Homev2 {...{ mainContentRef }} />} />
                <Route path="about-us" element={<About />} />
                <Route path="sign-in" element={<LoginTabs />} />
                <Route path="sign-in-otp" element={<LoginWithOTP />} />
                <Route path="sign-up" element={<Register />} />
                <Route path="startup-hub" element={<Contact />} />
                <Route
                  path="incubation-centre"
                  element={<IncubationCentre />}
                />
                <Route path="grievance-form" element={<GrievanceForm />} />
                <Route path="grievance-queries" element={<GrievanceDashboard />} />
                <Route path="proceedings" element={<Proceedings />} />
                <Route path="screen-reader" element={<ScreenReader />} />
              </Route>

              <Route
                path="mentor-register"
                // element={<MentorRegister />}
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      mentor_register?.show_menu ? (
                        <MentorRegisterIndex />
                      ) : mentor_register === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="mentor-register/upsert"
                // element={<MentorRegister />}
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      mentor_register?.show_menu ? (
                        <MentorRegister />
                      ) : mentor_register === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="mentor-register/upsert/:id"
                // element={<MentorRegister />}
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      mentor_register?.show_menu ? (
                        <MentorRegister />
                      ) : mentor_register === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="mentor-register/preview/:id"
                // element={<MentorRegister />}
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      mentor_register?.show_menu ? (
                        <MentorPreview />
                      ) : mentor_register === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />

              <Route
                path="/dashboard"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      dashboard?.show_menu ? (
                        <Dashboard />
                      ) : dashboard === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/mentor-dashboard"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      mentor_dashboard?.show_menu ? (
                        <MentorDashboard />
                      ) : mentor_dashboard === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/cms"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      cms?.show_menu ? (
                        <CMSIndex />
                      ) : cms === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/cms/upsert"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      cms?.show_menu ? (
                        <TextEditor />
                      ) : cms === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/cms/upsert/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      cms?.show_menu ? (
                        <TextEditor />
                      ) : cms === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/routes"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      routes?.show_menu ? (
                        <RoutesIndex />
                      ) : routes === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/roles"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      roles?.show_menu ? (
                        <RolesIndex />
                      ) : roles === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/permission"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      permission?.show_menu ? (
                        <PermissionIndex />
                      ) : role === "superadmin" ? (
                        <PermissionIndex />
                      ) : permission === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="permission/upsert"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      permission?.show_menu ? (
                        <UpsertPermissions />
                      ) : role === "superadmin" ? (
                        <UpsertPermissions />
                      ) : permission === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="permission/upsert/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      permission?.show_menu ? (
                        <UpsertPermissions />
                      ) : role === "superadmin" ? (
                        <UpsertPermissions />
                      ) : permission === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/applicant/preview/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      applicant?.show_menu ? (
                        <ApplicantPreview />
                      ) : applicant === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/applicant/logs/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      logs?.show_menu ? (
                        <LogsList />
                      ) : logs === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="log/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      logs?.show_menu ? (
                        <SingleLog />
                      ) : logs === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="prlog/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      logs?.show_menu ? (
                        <PRSingleLog />
                      ) : logs === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="recolog/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      logs?.show_menu ? (
                        <RecoLog />
                      ) : logs === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="mentorlog/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      logs?.show_menu ? (
                        <MentorLog />
                      ) : logs === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/incubation-user"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      incubation_user?.show_menu ? (
                        <IncubationTable />
                      ) : incubation_user === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="incubation-user/upsert"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      incubation_user?.show_menu ? (
                        <IncubationUser />
                      ) : incubation_user === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="incubation-user/upsert/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      incubation_user?.show_menu ? (
                        <IncubationUser />
                      ) : incubation_user === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              {/* {Cms related screens} */}
              <Route
                path="/category"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      category?.show_menu ? (
                        <CategoryIndex />
                      ) : category === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/post-type"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      post_type?.show_menu ? (
                        <PostTypeIndex />
                      ) : post_type === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/customfield"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      customfield?.show_menu ? (
                        <CustomFieldIndex />
                      ) : customfield === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/customfield/upsert"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      customfield?.show_menu ? (
                        <CustomField />
                      ) : customfield === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/customfield/upsert/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      customfield?.show_menu ? (
                        <CustomField />
                      ) : customfield === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              {/* legislative */}
              <Route
                path="/business-entity"
                // element={renderSidebar(
                //   <ProtectedRoute element={<BusinessEntityIndex />} />
                // )}
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      business_entity?.show_menu ? (
                        <BusinessEntityIndex />
                      ) : business_entity === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/sector"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      sector?.show_menu ? (
                        <SectorIndex />
                      ) : sector === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/sub-sector"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      sub_sector?.show_menu ? (
                        <SubSectorIndex />
                      ) : sub_sector === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/district"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      district?.show_menu ? (
                        <DistrictIndex />
                      ) : district === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/tehsil"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      tehsil?.show_menu ? (
                        <TehsilIndex />
                      ) : tehsil === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/legislative"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      legislative?.show_menu ? (
                        <LegislativeAssemblyIndex />
                      ) : legislative === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/parliament"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      parliament?.show_menu ? (
                        <ParliamentIndex />
                      ) : parliament === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/platform"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      platform?.show_menu ? (
                        <PlatformIndex />
                      ) : platform === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/progress-report/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      progress_report?.show_menu ? (
                        <ProgressReportIndex />
                      ) : progress_report === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/progress-report/:id/add"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      progress_report?.show_menu ? (
                        <UpsertProgressReport />
                      ) : progress_report === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/progress-report/:id/edit/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      progress_report?.show_menu ? (
                        <UpsertProgressReport />
                      ) : progress_report === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/progress-report/preview/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      progress_report?.show_menu ? (
                        <ProgressPreview />
                      ) : progress_report === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/priority"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      priority?.show_menu ? (
                        <PriorityIndex />
                      ) : priority === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/block"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      block?.show_menu ? (
                        <BlockIndex />
                      ) : block === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/state"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      state?.show_menu ? (
                        <StateIndex />
                      ) : state === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/applicant"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      applicant?.show_menu ? (
                        <Applicantv2 />
                      ) : applicant === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/application-status"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      application_status?.show_menu ? (
                        <ApplicationIndex />
                      ) : application_status === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/old-application"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      old_application?.show_menu ? (
                        <OldApplicationIndex />
                      ) : old_application === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/specialization"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      specialization?.show_menu ? (
                        <SpecializationIndex />
                      ) : specialization === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/recognition-form"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      recognition_form?.show_menu ? (
                        <RecognitionForm />
                      ) : recognition_form === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/recognition/preview/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      recognition?.show_menu ? (
                        <RecognitionPreview />
                      ) : recognition === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/agenda"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      agenda?.show_menu ? (
                        <AgendaIndex />
                      ) : agenda === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/agenda-list"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      agenda_list?.show_menu ? (
                        <AgendaList />
                      ) : agenda_list === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/startup-stage"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      startup_stage?.show_menu ? (
                        <StartUpStageIndex />
                      ) : startup_stage === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/select-mentor/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      select_mentor?.show_menu ? (
                        <MentorCard />
                      ) : select_mentor === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/mentor-info/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      select_mentor?.show_menu ? (
                        <MentorInfo />
                      ) : select_mentor === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/incubation-center"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      incubation_center?.show_menu ? (
                        <IncubationListIndex />
                      ) : incubation_center === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/grievances"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      grievances?.show_menu ? (
                        <GrievanceIndex />
                      ) : grievances === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />

              <Route
                path="/grievances/upsert"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      grievances?.show_menu ? (
                        <UpsertGrievance />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/grievances/upsert/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      grievances?.show_menu ? (
                        <UpsertGrievance />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/incubation-center/preview/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      incubation_center?.show_menu ? (
                        <IncubationPreview />
                      ) : incubation_center === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ): (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/grievances/chat/:id"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      grievances?.show_menu ? (
                        <Chat />
                      ) : grievances === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/recognition"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      recognition?.show_menu ? (
                        <RecognitionIndex />
                      ) : recognition === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              <Route
                path="/profile"
                element={renderSidebar(
                  <ProtectedRoute
                    element={
                      profile?.show_menu ? (
                        <Profile />
                      ) : profile === undefined ? (
                        <LoaderFallBack isload={isLoading} />
                      ) : (
                        <PermissionFallBack />
                      )
                    }
                  />
                )}
              />
              {/* <Route
              path="/industry"
              element={renderSidebar(
                <ProtectedRoute
                  element={
                    industry?.show_menu ? (
                      <IndustryIndex />
                    ) : industry === undefined ? (
                      <LoaderFallBack isload={isLoading} />
                    ) : (
                      <PermissionFallBack />
                    )
                  }
                />
              )}
            /> */}
              {/* <Route
              path="/progress-report/upsert"
              element={renderSidebar(
                <ProtectedRoute
                
                <ProtectedRoute
                  element={
                    progress_report?.show_menu ? (
                      <UpsertProgressReport />
                    ) : progress_report === undefined ? (
                      <LoaderFallBack isload={isLoading} />
                    ) : (
                      <PermissionFallBack />
                    )
                  }
                
              )}
                />
              )}
            /> */}
              {/* <Route path="/payment/upsert" element={renderSidebar(
                <ProtectedRoute element={<UpsertPayment/>} />
              )} />
            <Route path="/payment/upsert/:id" element={renderSidebar(
                <ProtectedRoute element={<UpsertPayment/>} />
              )} /> */}
              {/* <Route
              path="/applicant/?step=personal"
              element={renderSidebar(
                <ProtectedRoute element={<PersonalDetails />} />
              )}
            />
            <Route
              path="/applicant/?step=company"
              element={renderSidebar(
                <ProtectedRoute element={<CompanyDetails />} />
              )}
            /> */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            {/* <Footer /> */}
            {/* <Footerv2 /> */}
          </LanguageProvider>
        </ThemeContextProvider>
      </ErrorBoundary>
    </Box>
  );
};

export default App;
