import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material/";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  api,
  useDeleteApplicantMutation,
  useGetDistrictQuery,
  useGetOldApplicationQuery,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import generateCertificate from "../../Helpers/DownloadCertificate";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArticleIcon from "@mui/icons-material/Article";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PersonIcon from "@mui/icons-material/Person";
import LoaderFallBack from "../../Loader";
import moment from "moment";
import { blue, green, orange, red } from "@mui/material/colors";
import RestoreIcon from "@mui/icons-material/Restore";
import PreviewOldApplication from "./PreviewOldApplication";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const OldApplicationIndex = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("role").toLowerCase();
  const [district, setDistrict] = useState("");
  const {
    data: applicationList,
    isLoading,
    refetch,
  } = useGetOldApplicationQuery({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  });

  const [applicantDelete] = useDeleteApplicantMutation();
  const { data: districtList } = useGetDistrictQuery();
  const [applicationCertificate] =
    api.endpoints.generateApplicationCertificate.useLazyQuery();
  const [taskComplete] =
    api.endpoints.trackProgressReportTaskCompleteStatus.useLazyQuery();
  const [loading, setLoading] = useState(false);
  const [showCerti, setShowCerti] = useState(false);
  const [showOldApplication, setShowOldApplication] = useState(false);
  const [oldapplicationData, setOldApplicationData] = useState({});
  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        applicantDelete(id);
        swal("Application is deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("Application is safe!");
      }
    });
  };

  const handleEdit = (_id) => {
    localStorage.setItem("application_id", _id);
    navigate(`/applicant`);
  };

  const handleMentor = (id) => {
    navigate(`/select-mentor/${id}`);
  };
  useEffect(() => {
    refetch();
  }, [navigate]);

  const handleCheckCompletionoFTask = async (id) => {
    setLoading(true);
    try {
      const response = await taskComplete(id);
      if (response?.data?.success && response?.data?.data === 1) {
        await handleCertificate(id);
      } else {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCertificate = async (id) => {
    try {
      setLoading(true);
      const response = await applicationCertificate(id);
      if (response?.data?.success) {
        const certificateUrl = response?.data?.url;
        if (certificateUrl) {
          window.open(certificateUrl, "_blank");
          // toast.success(response?.data?.message, {
          //   position: "top-center",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
      } else {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getColorForRole = (status) => {
    switch (status) {
      case "selected":
        return green[500];
      case "reverted":
        return red[500];
      case null:
        return orange[500];
      default:
        return "defaultColor"; // Set default color if role name doesn't match any predefined color
    }
  };
  const handleClickOpen = (id) => {
    navigate(`/progress-report/${id}`);
  };
  const handleShowRec=(row)=>{
setShowOldApplication(true)
setOldApplicationData(row)
  }

  const getActionsWrapper = ({ params = null, role = null, loading }) => {
    const actions = [];
    const row = params?.row;

    // Common action: View Application button
    const viewApplicationButton = (
      <Button
        onClick={()=>handleShowRec(row)}
        title="View Application"
        variant="contained"
        size="small"
        color="info"
      >
        <Visibility />
        &nbsp; View Application
      </Button>
    );
    const LogsButton = (
      <Button
        onClick={() =>
          navigate(`/applicant/logs/${params?.id}`, {
            state: { type: "application" },
          })
        }
        title="View Logs"
        variant="contained"
        size="small"
        color="inherit"
      >
        <RestoreIcon />
        &nbsp; View Logs
      </Button>
    );

    switch (role) {
      case "user":
        if (!row?.finalSubmit) {
          // Actions for non-final submitted applications
          actions.push(
            viewApplicationButton
          );
        } else if (row?.finalSubmit) {
          // Actions for final submitted applications
          actions.push(viewApplicationButton);
        }
        break;

      case "incubation":
      case "mentor":
      case "dic":
      case "hod":
      case "admin":
      case "superadmin":
        if (row?.finalSubmit) {
          actions.push(
            viewApplicationButton,
            // <Button
            //   onClick={() => navigate(`/progress-report/${params?.id}`)}
            //   title="View PR"
            //   variant="contained"
            //   size="small"
            // >
            //   <Visibility />
            //   &nbsp; View PR
            // </Button>,
            //   <Button
            //   onClick={() => handleDelete(row._id)}
            //   title="Delete"
            //   variant="contained"
            //   size="small"
            //   color="error"
            // >
            //   <Delete />&nbsp; Delete
            // </Button>,
            // LogsButton
          );
        } else if (!row?.finalSubmit) {
          actions.push(viewApplicationButton);
        }
        break;

      // case "superadmin":
      // case "hod":
      // actions.push(

      //   viewApplicationButton,
      //   <Button
      //     onClick={() => navigate(`/progress-report/${params?.id}`)}
      //     title="View PR"
      //     variant="contained"
      //     size="small"
      //   >
      //     <Visibility />&nbsp; View PR
      //   </Button>
      // );
      // break;

      default:
        break;
    }

    return actions;
  };

  const handleReco = (id) => {
    navigate("/recognition-form", { state: id });
  };

  //   {
  //     "_id": "679b2ecdf85e1f8e1fdac8ca",
  //     "user_id": {
  //         "_id": "679b2ecdf85e1f8e1fdac84a",
  //         "name": "Sahil  Dutta",
  //         "mobile": "7831012202",
  //         "email": "sahildatta91@gmail.com"
  //     },
  //     "startup_name": "Backyard Garden Pvt. Ltd.",
  //     "sector": {
  //         "_id": "665d6f339664263df3f00aa2",
  //         "name": "Manufacturing"
  //     },
  //     "idea": "working towards the launch of 100% Natural packaged juices in the market, without any: \r\n1. Artificial Preservatives\r\n2. Added artificial flavours\r\n3.  Added artificial colour\r\n4.  NO added sugar\r\n5. Not from Concentrate",
  //     "social_public": "Was always passionate about experimenting with new mocktails and flavours of juice at home. Later realized a gap in the market with respect to the availability of ready to consume juices of healthy fruits like Amla. Hence wanted to work on making these functional juices available in the market ensuring it is healthy as well as tasty at the same time",
  //     "uniqueness": "1. No Added Sugar\r\n2. No added preservatives\r\n3. No added artificial flavors\r\n4. No added color\r\n5. No added artificial flavors\r\n6. Not from concentrate",
  //     "incubation_centre": {
  //         "_id": "679a2e201fc010a898dc9a0d",
  //         "name": "CSIR-Institute of Himalayan Bioresource Technology"
  //     },
  //     "business_entity_type": {
  //         "_id": "6639f7f286a4dcab837e58db",
  //         "name": "Limited Liability Partnership"
  //     },
  //     "business_address": "Pine Villa(ground floor), below block 6A, Sector-6, HIMUDA Colony Phase-3, Kangnadhar, New Shimla-171009(Himachal Pradesh)",
  //     "udyam_no": null,
  //     "initial_investment": 5000000,
  //     "funding_details": "In Phase 1- INR 7-10 lakhs",
  //     "commencementDate": null,
  //     "departmentRecommendationOn": null,
  //     "incubatorRecommendationOn": null,
  //     "incubationJoinedOn": null,
  //     "referenceId": "OHPSTARTUP/2018/06/53",
  //     "isStarted": "Y",
  //     "createdBy": 122,
  //     "updatedBy": null,
  //     "migrationId": null
  // },

  const columns = useMemo(
    () => [
      {
        field: "referenceId",
        headerName: "Ref. Id",
        flex: 1.5,
      },
      {
        field: "isStarted",
        headerName: "Started",
        flex: 1,
        renderCell: (params) => (
          <Box
            sx={{
              border:
                params?.row?.isStarted === "Y"
                  ? "solid 1px black"
                  : "solid 1px blue",
              borderRadiu: 5,
              backgroundColor: "lightgrey",
            }}
          >
            <Typography
              sx={{
                color: params?.row?.isStarted === "Y" ? "green" : "red",
                fontWeight: "bold",
                fontSize: "small",
              }}
            >
              {params?.row?.isStarted === "Y" ? "Yes" : "No"}
            </Typography>
          </Box>
        ),
      },
      {
        field: "departmentRecommendationOn",
        headerName: "Department Recommendation",
        flex: 1,
        renderCell: (params) => {
          if(params?.row?.departmentRecommendationOn===null) return "N/A";
                return (
                  <Typography>
                    {moment(params?.row?.departmentRecommendationOn).format("DD-MM-YYYY")}
                  </Typography>
                );
              },
      },
      {
        field: "incubatorRecommendationOn",
        headerName: "Incubator Recommendation",
        flex: 1,
        renderCell: (params) => {
          if(params?.row?.incubatorRecommendationOn===null) return "N/A";
                return (
                  <Typography>
                    {moment(params?.row?.incubatorRecommendationOn).format("DD-MM-YYYY")}
                  </Typography>
                );
              },
      },
      {
        field: "incubationJoinedOn",
        headerName: "Incubatoion Joining Date",
        flex: 1,
        renderCell: (params) => {
          if(params?.row?.incubationJoinedOn===null) return "N/A";
                return (
                  <Typography>
                    {moment(params?.row?.incubationJoinedOn).format("DD-MM-YYYY")}
                  </Typography>
                );
              },
      },
      {
        field: "business_address",
        headerName: "Business Address",
        flex: 2,
        renderCell: (params) => (
          <Typography style={{ whiteSpace: "normal", wordBreak: "break-word", fontSize:"small"}}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 2,
        renderCell: (params) => (
          <Grid
            container
            sx={{
              overflowX: "auto",
              gap: "8px",
            }}
          >
            {getActionsWrapper({ params, role, loading })}
          </Grid>
        ),
      },
    ],
    [role, loading]
  );

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize.pageSize }));
  }, []);

  const handlePaginationModelChange = useCallback((data) => {
    setPaginationModel(data);
  }, []);

  const handlePdf = async () => {
    return null;
    // const headers = [
    //   { title: "Aadhaar", id: "aadhaar" },
    //   { title: "Application Stage", id: "application_stage" },
    //   { title: "Business Address", id: "business_address" },
    //   { title: "Category", id: "category" },
    //   { title: "Co founders", id: "co_founders" },
    //   { title: "Contact", id: "contact" },
    //   { title: "Email", id: "email" },
    //   { title: "Revenue", id: "revenue" },
    //   { title: "Uniqueness", id: "uniqueness" },
    //   { title: "Women Led", id: "women_led" },
    // ];

    // const doc = new jsPDF({
    //   orientation: "landscape",
    //   // unit: "in",
    //   // format: [8, 6]
    // });

    // try {
    //   const applicationData = applicationList?.data?.AllApplication || [];
    //   const tableData = applicationData.map((item) => {
    //     return headers.reduce((acc, header) => {
    //       acc[header.id] = item[header.id] || "";
    //       return acc;
    //     }, {});
    //   });

    //   doc.autoTable({
    //     head: [headers.map((header) => header.title)],
    //     body: tableData.map((item) => headers.map((header) => item[header.id])),
    //     margin: { top: 10 },
    //     styles: { fontSize: 10 },
    //   });

    //   doc.save("application_list.pdf");
    // } catch (error) {
    //   console.error("Error generating PDF:", error);
    // }
  };

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      <Paper className="paper-card">
        <Grid container>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <Typography variant="h5">Old Application List</Typography>
          </Grid>
          {applicationList?.data?.length > 0 ? (
            <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
              <Tooltip title="Download PDF" arrow>
                <IconButton sx={{ mt: 1 }} onClick={handlePdf} color="primary">
                  <DownloadIcon sx={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          {/* {role === "dic" ? (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={3}>
             
              <FormControl fullWidth>
                <InputLabel id="district">District</InputLabel>
                <Select
                  margin="dense"
                  fullWidth
                  id="district"
                  label="District"
                  name="district"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                >
                  {districtList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null} */}

          {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={3}>
            <FormControl fullWidth>
              <InputLabel id="application_type">Application Type</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="application_type"
                label="Application Type"
                name="application_type"
                value={applicationType}
                onChange={(e) => setApplicationType(e.target.value)}
                // onBlur={formik.handleBlur}
                // error={
                //   formik.touched.district && Boolean(formik.errors.district)
                // }
                // helperText={formik.touched.district && formik.errors.district}
              >
                <MenuItem value="">--Select Status--</MenuItem>
                <MenuItem value="old_application">Old Application</MenuItem>
                <MenuItem value="new_application">New Application</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          

        </Grid>

        <Box marginTop={2} sx={{ width: "100%", height: "500px" }}>
          <DataGrid
            columns={columns}
            // autoHeight
            // rowHeight={52} // Adjusted row height
            getRowHeight={() => 150} // Set the row height here
            rowCount={applicationList?.data?.length || 0}
            getRowId={(row) => row._id}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
            pagination
            paginationModel={paginationModel}
            paginationMode="server"
            onPageSizeChange={handlePageSizeChange}
            onPaginationModelChange={handlePaginationModelChange}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              toolbar: GridToolbar,
            }}
            sx={{
              width: "100%",
              marginBottom: "16px",
              "& .MuiDataGrid-cell": {
                py: 1, // Adjust padding of cells
              },
            }}
            rows={applicationList?.data || []}
            />
        </Box>
            {showOldApplication?<PreviewOldApplication {...{showOldApplication, setShowOldApplication, oldapplicationData}}/>:null}
      </Paper>
    </Box>
  );
};

export default OldApplicationIndex;
