import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux"; // Assuming you're using Redux for state management
import { setUserProfile } from "../../Redux/GlobalSlice"; // Action to set the user profile in the global state
import UploadIcon from "@mui/icons-material/Upload";
import {
  api,
  useCreateProfileMutation,
  useGetProfileQuery,
} from "../../services/api";
import userSchema from "./constants";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      bio: "",
      image: "", // Default image
      gender: "",
      preview_image: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        let data = {
          name: values.name,
          // email: values.email,
          phone: values.phone,
          password: values.password,
          bio: values.bio,
          image: values.image, // Default image
          gender: values.gender,
        };
        const formData = new FormData();

        // Append each key-value pair to the FormData object
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        const value = await createProfile(formData);

        if (value?.error) {
          toast.error(value?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        if (value?.data?.success) {
          toast.success("Profile is updated successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          formik.resetForm();
          navigate("/dashboard");

          // toggleDrawer("right", false)(values);
        }
      } catch (error) {
        console.log("error=>", error);
      }
    },
  });
  const [createProfile] = useCreateProfileMutation();
  const [getProfile] = api.endpoints.getProfile.useLazyQuery();
  const navigate = useNavigate();
  const dp = useSelector((state) => state?.data?.data?.userProfile);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  const handleChange = (event) => {
    const { name, files } = event.target;
    formik.setFieldValue("preview_image", URL.createObjectURL(files[0]));
    if (name === "image" && files.length > 0) {
      const file = files[0];
      dispatch(
        setUserProfile({
          image: URL.createObjectURL(file),
        })
      );
      formik.setFieldValue(name, file);
    } else {
      formik.handleChange(event);
    }
  };

  useEffect(() => {
    const Updatedp = async () => {
      try {
        const { data } = await getProfile();
        const {
          name,
          password,
          bio,
          image, // Default image
          gender,
          mobile,
          email,
        } = data?.data;
        console.log("🚀 ~ Updatedp ~ bio:", bio);
        dispatch(
          setUserProfile({
            ...dp,
            final_image: image,
          })
        );

        await formik.setValues((prev) => ({
          ...prev,
          name,
          phone: mobile,
          bio: bio !== undefined ? bio : "",
          image,
          gender,
          email,
        }));
      } catch (error) {
        console.error("Error fetching mentor data:", error);
      }
    };
    Updatedp();
  }, []);

  // const handleSave = async () => {
  //   setIsEditing(false);

  //   // Implement save functionality here (e.g., API call)
  //   dispatch(setUserProfile(profile)); // Update the global state with the new profile
  // };
  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      <Paper className="paper-card">
        <Box>
          <CardHeader
            avatar={
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  // accept=".png.jpg"
                  src={formik.values.preview_image || formik.values.image}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    opacity: 0,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                  onClick={() => document.getElementById("imageInput").click()}
                >
                  <UploadIcon color="error" />
                </IconButton>
                <input
                  id="imageInput"
                  name="image"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => handleChange(event)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.image && Boolean(formik.errors.image)}
                  helperText={formik.touched.image && formik.errors.image}
                />
              </Box>
            }
            action={
              <IconButton onClick={handleEditToggle} color="primary">
                {isEditing ? (
                  <SaveIcon color="primary" />
                ) : (
                  <EditIcon color="success" />
                )}
              </IconButton>
            }
            title={
              <Typography variant="h5">
                {isEditing ? (
                  <Grid container spacing={2}>
                    <Grid item md={6} lg={6} xl={6}>
                      <Typography variant="subtitle1">Name</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                    <Grid item md={6} lg={6} xl={6}>
                      <Typography variant="subtitle1">Gender</Typography>
                      <Select
                        fullWidth
                        variant="outlined"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        helperText={
                          formik.touched.gender && formik.errors.gender
                        }
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                ) : (
                  formik.values.name
                )}
              </Typography>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} lg={6} xl={6} xs={12}>
                <Typography variant="subtitle1">Email</Typography>
                {isEditing ? (
                  <TextField
                    fullWidth
                    disabled={true}
                    aria-readonly={true}
                    variant="outlined"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                ) : (
                  <Typography>{formik.values.email}</Typography>
                )}
              </Grid>
              <Grid item md={6} lg={6} xl={6} xs={12}>
                <Typography variant="subtitle1">Phone Number</Typography>
                {isEditing ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                ) : (
                  <Typography>{formik.values.phone}</Typography>
                )}
              </Grid>
              <Grid item md={6} lg={6} xl={6} xs={12}>
                <Typography variant="subtitle1">Password</Typography>
                {isEditing ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Typography>••••••••</Typography>
                )}
              </Grid>
              <Grid item md={6} lg={6} xl={6} xs={12}>
                <Typography variant="subtitle1">Bio</Typography>
                {isEditing && formik.values.bio !== undefined ? (
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    name="bio"
                    value={formik.values.bio}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.bio && Boolean(formik.errors.bio)}
                    helperText={formik.touched.bio && formik.errors.bio}
                  />
                ) : (
                  <Typography>{formik.values.bio}</Typography>
                )}
              </Grid>
              {/* <Grid item md={6} lg={6} xl={6} xs={12}>
                <Select
                  type="select"
                  fullWidth
                  variant="outlined"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  helperText={formik.touched.gender && formik.errors.gender}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                </Select>
              </Grid> */}
            </Grid>
            {isEditing && (
              <Box sx={{ mt: 2, textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                >
                  Save Changes
                </Button>
              </Box>
            )}
          </CardContent>
        </Box>
      </Paper>
    </Box>
  );
};

export default Profile;
